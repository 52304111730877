import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
    getCreateRulePayload,
    getUpdateRulePayload,
} from '../../services/api/apiPayloads';
import {
    createRule,
    deleteRule,
    getRule,
    updateRule,
} from '../../services/api/ruleApi';
import * as C from '../../services/constants';
import { extractPoints, parseRule } from '../../services/ruleUtils';
import { normalizeString } from '../../services/typeUtils';
import { hideLoading, showLoading } from '../../store/actions/loadingActions';
import {
    notify,
    notifyError,
    notifySuccess,
} from '../../store/actions/notificationActions';
import ConditionCreator from './ConditionCreator';
import ConfirmDialog from './ConfirmDialog';
import { conditionFields } from './RuleFields';

import closeIcon from '../../resources/images/close-rule-editor.svg';
import DynamicRuleEditor from './DynamicRuleEditor/DynamicRuleEditor';
import {
    ATTRIBUTE_TYPE_BASED_ON_OPERATION,
    generateMvelExpression,
    parseActionExpression,
    parseMvelExpression,
} from './DynamicRuleEditor/utils/utils';
import { getActionList, getEntityAttribute } from '../../services/api/nbaApi';
import ActionCreator from './DynamicRuleEditor/ActionCreator';
// import {
//     createRule,
//     deleteRule,
//     getRule,
//     updateRule,
// } from '../../services/api/nbaApi';

const RuleEditor = ({ onClose, ruleId, status, type, origin }) => {
    const domains = Object.keys(conditionFields);

    const dispatch = useDispatch();
    const { auth } = useSelector((state) => state);
    const { market, category, brand } = auth.user;
    const [domain, setDomain] = useState(domains[0]);
    const [event, setEvent] = useState({
        // domain: domain,
        entity: domain,
        attributeName: 'register',
        operation: '==',
        attributeValue: 'NOT_REQUIRED',
    });

    const [rulesData, setRulesData] = useState([]);
    // const [actionsData, setActionsData] = useState([
    //     { actionId: '', priority: '' },
    // ]);
    const [loading, setLoading] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [action, setAction] = useState([
        {
            domain: 'CONSUMER',
            attributeName: 'points',
            operation: '==',
            attributeValue: '',
        },
    ]);
    const [description, setDescription] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(true);
    const [confirmDialog, setConfirmDialog] = useState({
        display: false,
        title: '',
        message: '',
        note: '',
        onClose: null,
        onConfirm: null,
        confirmText: '',
        backText: '',
    });

    const handleDomainChange = (e) => {
        const selectedDomain = e.target.value;
        const defaultEventAttributes = conditionFields[selectedDomain].reduce(
            (acc, field) => {
                if (field.type === 'dropdown') {
                    acc[field.name] = field.values[0] || '';
                } else {
                    acc[field.name] = '';
                }
                return acc;
            },
            {}
        );
        setDomain(selectedDomain);
        setEvent({
            domain: selectedDomain,
            ...defaultEventAttributes,
        });
        setConditions([]);
    };

    const handleEventChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'attributeValue': {
                if (C.REGEX_RULE_EVENT_ATTRIBUTE.test(value) || value === '') {
                    setEvent((prevEvent) => {
                        const updatedEvent = {
                            ...prevEvent,
                            attributeValue: value,
                        };
                        return updatedEvent;
                    });
                }
                break;
            }
            default: {
                setEvent((prevEvent) => {
                    const updatedEvent = { ...prevEvent, [name]: value };
                    return updatedEvent;
                });
                break;
            }
        }
    };
    const handleAddCondition = () => {
        setConditions((prevConditions) => [
            ...prevConditions,
            {
                join: '&&',
                domain: 'CONSUMER',
                attributeName: 'points',
                operation: '>=',
                attributeValue: '',
            },
        ]);
    };

    const handleDeleteCondition = (index) => {
        // if (index === 0 && conditions.length === 1) {
        //     return;
        // }
        setConditions((prevConditions) =>
            prevConditions.filter((_, i) => i !== index)
        );
    };

    const handleConditionChange = (newCondition, index) => {
        setConditions((prevConditions) => {
            const updatedConditions = [...prevConditions];
            updatedConditions[index] = newCondition;
            return updatedConditions;
        });
    };

    const handleActionChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'attributeValue': {
                if (C.REGEX_RULE_USER_POINTS.test(value) || value === '') {
                    setAction((prevAction) => ({
                        ...prevAction,
                        attributeValue: value,
                    }));
                }
                break;
            }
            default: {
                setAction((prevAction) => {
                    const updatedAction = { ...prevAction, [name]: value };
                    return updatedAction;
                });
                break;
            }
        }
    };
    const handleDescriptionChange = (e) => {
        const { value } = e.target;
        if (
            C.REGEX_RULE_DESCRIPTION.test(normalizeString(value)) ||
            value === ''
        ) {
            setDescription(value);
        }
    };
    const validateRulesData = () => {
        const stateVariables = [event, ...conditions, action, description];
        const hasNullOrUndefined = (variable) =>
            variable === null || variable === undefined;

        const hasEmptyValue = stateVariables.some((variable) => {
            if (hasNullOrUndefined(variable)) {
                return true;
            }

            if (typeof variable === 'object') {
                return Object.values(variable).some(
                    (value) => hasNullOrUndefined(value) || value.trim() === ''
                );
            } else if (typeof variable === 'string') {
                return variable.trim() === '';
            }

            return false;
        });

        return !hasEmptyValue;
    };
    const generateRule = () => {
        let ruleAction = `output.setPoints(${action.attributeValue.trim()});output.setoNamespace('LOYALTY_TRANSACTION')`;

        let triggerString = `(input.domain == '${event.domain.trim()}' && input.${
            event.attributeName
        } == ${
            event.domain !== 'CONSUMER'
                ? `'${event.attributeValue.trim()}'`
                : 'true'
        })`;

        let conditionString = conditions
            .map((condition) => {
                return `${condition.join} (${
                    condition.domain !== 'CONSUMER'
                        ? `input.domain == '${condition.domain.trim()}' && `
                        : ''
                }input.${condition.attributeName.trim()} ${condition.operation.trim()} '${condition.attributeValue.trim()}')`;
            })
            .join(' ');

        let ruleCondition = `${triggerString} ${
            event.attributeName !== 'register' ? conditionString : ''
        }`.trim();

        return [ruleCondition, ruleAction];
    };

    const saveRule = () => {
        const [ruleCondition, ruleAction] = generateMvelExpression(
            rulesData,
            action,
            ATTRIBUTE_TYPE_BASED_ON_OPERATION
        );
        if (ruleId !== null) {
            // update rule
            const updateRuleBody = {
                description,
                condition: ruleCondition,
                action: ruleAction,
                status: 'DRAFT',
                ruleType: 'POINT',
            };
            dispatch(showLoading());
            updateRule(market, category, brand, ruleId, updateRuleBody)
                .then(() => {
                    onClose();
                    dispatch(
                        notify('Saved!', 'Your work has been saved in drafts.')
                    );
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to save draft.'));
                })
                .finally(() => dispatch(hideLoading()));
        } else {
            // create rule
            const createRuleBody = {
                namespace: 'CONSUMER',
                description,
                condition: ruleCondition,
                action: ruleAction,
                status: 'DRAFT',
                ruleType: 'POINT',
            };
            dispatch(showLoading());
            createRule(market, category, brand, createRuleBody)
                .then(() => {
                    onClose();
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to save draft.'));
                })
                .finally(() => dispatch(hideLoading()));
        }
    };
    const expireRule = () => {
        const [ruleCondition, ruleAction] = generateMvelExpression(
            rulesData,
            action,
            ATTRIBUTE_TYPE_BASED_ON_OPERATION
        );

        const expireRuleBody = {
            description,
            condition: ruleCondition,
            action: ruleAction,
            ruleType: 'POINT',
            status: 'EXPIRED',
        };
        dispatch(showLoading());
        updateRule(market,category, brand, ruleId, expireRuleBody)
            .then(() => {
                onClose();
                dispatch(
                    notifySuccess(
                        'Rule Expired!',
                        'The Consumer rule has been successfully expired.'
                    )
                );
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to expire rule.'));
            })
            .finally(() => dispatch(hideLoading()));
    };
    const removeRule = () => {
        dispatch(showLoading());
        deleteRule(market, category, brand, ruleId)
            .then(() => {
                onClose();
                dispatch(
                    notify(
                        'Deleted Draft!',
                        'Draft has been successfully deleted.'
                    )
                );
            })
            .catch((error) => {
                dispatch(notifyError('Error!', 'Unable to delete draft.'));
            })
            .finally(() => dispatch(hideLoading()));
    };
    const publishRule = () => {
        // const [ruleCondition, ruleAction] = generateRule();
        const [ruleCondition, ruleAction] = generateMvelExpression(
            rulesData,
            action,
            ATTRIBUTE_TYPE_BASED_ON_OPERATION
        );
        if (ruleId !== null) {
            const publishRuleBody = {
                description,
                condition: ruleCondition,
                action: ruleAction,
                status: 'PUBLISHED',
                ruleType: 'POINT',
            };
            dispatch(showLoading());
            updateRule(market, category, brand, ruleId, publishRuleBody)
                .then(() => {
                    onClose();
                    dispatch(
                        notifySuccess(
                            'Published!',
                            'The rule has been successfully published.'
                        )
                    );
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to publish rule.'));
                })
                .finally(() => dispatch(hideLoading()));
        } else {
            const createRuleBody = {
                namespace: 'CONSUMER',
                description,
                condition: ruleCondition,
                action: ruleAction,
                status: 'PUBLISHED',
                ruleType: 'POINT',
            };
            dispatch(showLoading());
            createRule(market, category, brand, createRuleBody)
                .then(() => {
                    dispatch(
                        notifySuccess(
                            'Published!',
                            'The rule has been successfully published.'
                        )
                    );
                    onClose();
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to publish rule.'));
                })
                .finally(() => dispatch(hideLoading()));
        }
    };
    const closeConfirmDialog = () => {
        setConfirmDialog({ ...confirmDialog, display: false });
    };
    useEffect(() => {
        if (ruleId !== null) {
            setLoading(true);
            dispatch(showLoading());
            getRule(market, category, brand, ruleId)
                .then((response) => {
                    const rule = response?.data;
                    setRulesData((prevrule) => {
                        return parseMvelExpression(rule?.condition);
                    });
                    setAction((prevAction) => {
                        return parseActionExpression(rule?.action);
                    });
                    setDescription(rule?.description);
                })
                .catch((error) => {
                    dispatch(notifyError('Error!', 'Unable to fetch rules.'));
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(hideLoading());
                });
        }
    }, []);
    // useEffect(() => {
    //     const isValidData = validateRulesData();
    //     type === C.RULE_TYPE_EXPIRED
    //         ? setSaveEnabled(false)
    //         : setSaveEnabled(isValidData);
    // }, [actionsData, action, description]);

    return (
        <div
            className={`create-rule-container ${
                origin === C.RULE_EDITOR_MANAGE_PROGRAM &&
                'manage-rule-container'
            }`}
        >
            <div className="create-rule">
                <div className="create-rule-header">
                    <div className="create-rule-close">
                        <button
                            className="create-rule-close-btn"
                            onClick={onClose}
                        >
                            {/* <img src={closeIcon} alt="close" /> */}
                            <ArrowBackIcon />
                            {origin === C.RULE_EDITOR_CREATE_PROGRAM
                                ? ' Back to Rules'
                                : 'Back to Manage Rules'}
                        </button>
                    </div>
                    <div className="create-rule-title">
                        {origin === C.RULE_EDITOR_CREATE_PROGRAM ? (
                            <>
                                <h3>Create New Rule</h3>
                                <p>* All fields are mandatory</p>
                            </>
                        ) : type === C.RULE_TYPE_EXPIRED ? (
                            <h3>View Expired Rule</h3>
                        ) : type === C.RULE_TYPE_PUBLISHED ? (
                            <h3>View Published Rule</h3>
                        ) : (
                            <h3>Add Rule</h3>
                        )}
                    </div>
                </div>
                <fieldset className="dynamicRuleEditor">
                    <legend className="customLegend">Trigger Rule WHEN</legend>
                    {!loading && (
                        <DynamicRuleEditor
                            rulesData={rulesData}
                            setData={setRulesData}
                            isPublished={C.RULE_INPUT_DISABLED.includes(type)}
                        />
                    )}
                </fieldset>

                <fieldset className="dynamicRuleEditor">
                    <legend className="customLegend">
                        THEN system must do the following
                    </legend>
                    {!loading && (
                        <ActionCreator
                            actionsData={action}
                            setData={(data) => setAction(data)}
                            isPublished={C.RULE_INPUT_DISABLED.includes(type)}
                        />
                    )}
                </fieldset>
                <fieldset className="dynamicRuleEditor rule-description-container">
                    <div className="info-container">
                        <h4>Rule Description:</h4>
                    </div>
                    <input
                        type="text"
                        id="ruleDescription"
                        name="description"
                        onChange={(e) => handleDescriptionChange(e)}
                        value={description}
                        placeholder="If user has registered then add 100 points and assign 'Rookie' level to user"
                        disabled={C.RULE_INPUT_DISABLED.includes(type)}
                    />
                </fieldset>
                <div className="create-rule-cta-container">
                    {origin === C.RULE_EDITOR_CREATE_PROGRAM ? (
                        <>
                            <button
                                className={`save-rule ${
                                    saveEnabled ? 'active' : 'inactive'
                                }`}
                                onClick={() => saveEnabled && saveRule()}
                            >
                                Save Rule
                            </button>
                            {ruleId && (
                                <button
                                    className={`save-rule ${
                                        saveEnabled ? 'active' : 'inactive'
                                    }`}
                                    onClick={() => saveEnabled && removeRule()}
                                >
                                    Delete Rule
                                </button>
                            )}
                        </>
                    ) : type === C.LOYALTY_PROGRAM_STATUS_PUBLISHED ? (
                        <button
                            className="expire-rule"
                            onClick={() =>
                                setConfirmDialog({
                                    display: true,
                                    title: 'Confirm: Expire Rule!',
                                    message:
                                        'Are you sure you want to expire this Consumer rule?',
                                    note: 'Once you expire this rule will no longer be triggered or be re-activated.',
                                    onConfirm: expireRule,
                                    onClose: closeConfirmDialog,
                                    confirmText: 'Yes, Expire Rule',
                                    backText: 'No, Do Not Expire',
                                })
                            }
                        >
                            Expire Rule
                        </button>
                    ) : (
                        <>
                            <button
                                className={`publish-rule ${
                                    saveEnabled ? 'active' : 'inactive'
                                }`}
                                // className="publish-rule active"
                                onClick={() => {
                                    if (
                                        !description ||
                                        !rulesData.length
                                        //!action.length
                                    ) {
                                        setConfirmDialog({
                                            display: true,
                                            title: 'Please Resolve All Error',
                                            note: 'Check if Description, Action, Rules are added Properly',
                                            // onConfirm: publishRule,
                                            onClose: closeConfirmDialog,
                                            // confirmText: 'Yes, Publish Rule',
                                            backText: 'Close',
                                        });
                                    } else if (saveEnabled) {
                                        setConfirmDialog({
                                            display: true,
                                            title: 'Confirm: Publish Rule!',
                                            note: 'Once you publish here you can not edit or update this rule. You can add new rules or expire the rule in manage rules.',
                                            onConfirm: publishRule,
                                            onClose: closeConfirmDialog,
                                            confirmText: 'Yes, Publish Rule',
                                            backText: 'No, Continue Editing',
                                        });
                                    }
                                }}
                            >
                                Publish Rule
                            </button>
                            <button
                                className={`save-rule ${
                                    saveEnabled ? 'active' : 'inactive'
                                }`}
                                // className='save-rule active'
                                // onClick={() =>
                                //     // generateMvelExpression(rulesData, actionsData, ATTRIBUTE_TYPE_BASED_ON_OPERATION)
                                //     saveEnabled &&
                                //     setConfirmDialog({
                                //         display: true,
                                //         title: 'Confirm: Save as Draft',
                                //         message:
                                //             'Are you sure you want to save as draft and navigate away from this screen?',
                                //         onConfirm: saveRule,
                                //         onClose: closeConfirmDialog,
                                //         confirmText: 'Yes, Save and Close',
                                //         backText: 'No, Continue Editing',
                                //     })
                                // }
                                onClick={() => {
                                    if (
                                        !description ||
                                        !rulesData.length
                                        //!action.length
                                    ) {
                                        setConfirmDialog({
                                            display: true,
                                            title: 'Please Resolve All Error',
                                            note: 'Check if Description, Action, Rules are added Properly',
                                            // onConfirm: publishRule,
                                            onClose: closeConfirmDialog,
                                            // confirmText: 'Yes, Publish Rule',
                                            backText: 'Close',
                                        });
                                    } else if (saveEnabled) {
                                        setConfirmDialog({
                                            display: true,
                                            title: 'Confirm: Save as Draft',
                                            message:
                                                'Are you sure you want to save as draft and navigate away from this screen?',
                                            onConfirm: saveRule,
                                            onClose: closeConfirmDialog,
                                            confirmText: 'Yes, Save and Close',
                                            backText: 'No, Continue Editing',
                                        });
                                    }
                                }}
                            >
                                Save as Draft
                            </button>
                            <button
                                className={`delete-rule ${
                                    ruleId && saveEnabled
                                        ? 'active'
                                        : 'inactive'
                                }`}
                                // className='delete-rule active'
                                onClick={() =>
                                    ruleId &&
                                    // saveEnabled &&
                                    setConfirmDialog({
                                        display: true,
                                        title: 'Confirm: Delete Draft',
                                        message:
                                            'Are you sure you want to delete this draft and navigate away from this screen?',
                                        onConfirm: removeRule,
                                        onClose: closeConfirmDialog,
                                        confirmText: 'Yes, Delete Draft',
                                        backText: 'No, Continue Editing',
                                    })
                                }
                            >
                                Delete
                            </button>
                        </>
                    )}
                </div>
            </div>
            {confirmDialog.display && (
                <ConfirmDialog
                    title={confirmDialog.title}
                    message={confirmDialog.message}
                    note={confirmDialog.note}
                    onClose={confirmDialog.onClose}
                    onConfirm={confirmDialog.onConfirm}
                    confirmText={confirmDialog.confirmText}
                    backText={confirmDialog.backText}
                />
            )}
        </div>
    );
};

export default RuleEditor;
