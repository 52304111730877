// export const eventFields = {
//     CONSUMER: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['register', 'login'],
//         },
//     ],
//     GAME: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['id'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     VIDEO: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['id'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     ARTICLE: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['id'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     QUIZ: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['id'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     RAFFLE: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['id'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
// };
// export const conditionFields = {
//     CONSUMER: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['eventFrequency', 'eventCount', 'points'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['==', '>', '<', '>=', '<='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     GAME: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['title'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     VIDEO: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['title'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     ARTICLE: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['title'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     QUIZ: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['title'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
//     RAFFLE: [
//         {
//             label: 'Event Attribute Is:',
//             name: 'attributeName',
//             type: 'dropdown',
//             values: ['title'],
//         },
//         {
//             label: 'Operation:',
//             name: 'operation',
//             type: 'dropdown',
//             values: ['=='],
//         },
//         {
//             label: 'Attribute Value Is:',
//             name: 'attributeValue',
//             type: 'text',
//         },
//     ],
// };

export const conditionFields = {
    success: true,
    context: {
        requestId: '7937f755-0c3f-4c74-8cfa-4baf4ef3377d',
        correlationId: '1c83a5b5-2c00-4523-bfd3-e09007a6a4ac',
    },
    data: []
};

// New Editor Json
export const EditorConfig = {
    AR: {
        CC: {
            MARLBORO: {
                data: [
                    {
                        nbaEntityId: 17,
                        name: 'asset',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 53,
                                name: 'id',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 54,
                                name: 'type',
                                valueOption: 'Game,Video,Article,Quiz,Raffle',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 55,
                                name: 'category',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 19,
                        name: 'profile',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 57,
                                name: 'register',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 58,
                                name: 'login',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 59,
                                name: 'completeProfile',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },

                            {
                                nbaEntityAttributeId: 60,
                                name: 'loginFrequency',
                                valueOption: 'daily,weekly,monthly,yearly',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 61,
                                name: 'loginCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 62,
                                name: 'consumerPoints',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 63,
                                name: 'consumerTier',
                                valueOption: 'bronze,silver,gold',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                        ],
                    },
                ],
            },
        },
    },
    PH: {
        CC: {
            FORTUNE: {
                data: [
                    {
                        nbaEntityId: 17,
                        name: 'asset',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 53,
                                name: 'id',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 54,
                                name: 'type',
                                valueOption: 'Game,Video,Article,Quiz,Raffle',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 55,
                                name: 'category',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 18,
                        name: 'upc',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 56,
                                name: 'skuCode',
                                valueOption: 'ABC,XYZ,PQR',
                                supportedOperation: '==,is_multiple',
                                attributeType: 'string',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 19,
                        name: 'profile',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 57,
                                name: 'register',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 58,
                                name: 'login',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 59,
                                name: 'completeProfile',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },

                            {
                                nbaEntityAttributeId: 60,
                                name: 'loginFrequency',
                                valueOption: 'daily,weekly,monthly,yearly',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 61,
                                name: 'loginCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 62,
                                name: 'consumerPoints',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 63,
                                name: 'consumerTier',
                                valueOption: 'bronze,silver,gold',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 64,
                                name: 'isReferral',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 65,
                                name: 'isUpcUploaded',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 67,
                                name: 'preferredLanguage',
                                valueOption: 'EN',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 68,
                                name: 'registrationBrand',
                                valueOption: 'FORTE,MARLB,CHEST,PHILI',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 69,
                                name: 'channelRefCode',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 70,
                                name: 'registrationDate',
                                valueOption: null,
                                supportedOperation:
                                    'after,before,on_or_after,on_or_before,on,not_on,between_dates,not_between_dates,between_period,not_between_period,within_this,not_within_this',
                                attributeType: 'date',
                            },
                            {
                                nbaEntityAttributeId: 71,
                                name: 'profileVerified',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 72,
                                name: 'isActive',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 73,
                                name: 'privacyPolicy',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 74,
                                name: 'databaseOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 75,
                                name: 'smokerDeclaration',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 76,
                                name: 'marketingOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 77,
                                name: 'brandsOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 78,
                                name: 'smsOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 79,
                                name: 'dmOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 80,
                                name: 'callOptin',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },

                            {
                                nbaEntityAttributeId: 81,
                                name: 'verifiedByIdMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 82,
                                name: 'verifiedByDataMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 83,
                                name: 'verifiedByFACECAPTURE',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 84,
                                name: 'verifiedByF2F',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 20,
                        name: 'godfather',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 85,
                                name: 'weeklyReferralCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 86,
                                name: 'monthlyReferralCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 87,
                                name: 'yearlyReferralCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 88,
                                name: 'verifiedByIdMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 89,
                                name: 'verifiedByDataMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 90,
                                name: 'verifiedByFACECAPTURE',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 91,
                                name: 'verifiedByF2F',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                        ],
                    },
                ],
            },
        },
    },
    ID: {
        CC: {
            MARLBORO: {
                data: [
                    {
                        nbaEntityId: 17,
                        name: 'asset',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 53,
                                name: 'id',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 54,
                                name: 'type',
                                valueOption: 'Game,Video,Article,Quiz,Raffle',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 55,
                                name: 'category',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 18,
                        name: 'upc',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 56,
                                name: 'skuCode',
                                valueOption: 'ABC,XYZ,PQR',
                                supportedOperation: '==,is_multiple',
                                attributeType: 'string',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 19,
                        name: 'profile',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 57,
                                name: 'register',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 58,
                                name: 'login',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 59,
                                name: 'completeProfile',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },

                            {
                                nbaEntityAttributeId: 60,
                                name: 'loginFrequency',
                                valueOption: 'daily,weekly,monthly,yearly',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 61,
                                name: 'loginCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 62,
                                name: 'consumerPoints',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 63,
                                name: 'consumerTier',
                                valueOption: 'bronze,silver,gold',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 64,
                                name: 'isReferral',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 65,
                                name: 'isUpcUploaded',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 67,
                                name: 'preferredLanguage',
                                valueOption: 'EN',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 68,
                                name: 'registrationBrand',
                                valueOption: 'FORTE,MARLB,CHEST,PHILI',
                                supportedOperation: '==',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 69,
                                name: 'channelRefCode',
                                valueOption: null,
                                supportedOperation: 'equals',
                                attributeType: 'string',
                            },
                            {
                                nbaEntityAttributeId: 70,
                                name: 'registrationDate',
                                valueOption: null,
                                supportedOperation:
                                    'after,before,on_or_after,on_or_before,on,not_on,between_dates,not_between_dates,between_period,not_between_period,within_this,not_within_this',
                                attributeType: 'date',
                            },
                            {
                                nbaEntityAttributeId: 71,
                                name: 'profileVerified',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 72,
                                name: 'isActive',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 73,
                                name: 'privacyPolicy',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 74,
                                name: 'databaseOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 75,
                                name: 'smokerDeclaration',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 76,
                                name: 'marketingOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 77,
                                name: 'brandsOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 78,
                                name: 'smsOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 79,
                                name: 'dmOptIn',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 80,
                                name: 'callOptin',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },

                            {
                                nbaEntityAttributeId: 81,
                                name: 'verifiedByIdMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 82,
                                name: 'verifiedByDataMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 83,
                                name: 'verifiedByFACECAPTURE',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 84,
                                name: 'verifiedByF2F',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                        ],
                    },
                    {
                        nbaEntityId: 20,
                        name: 'godfather',
                        parentEntityId: null,
                        entityType: null,
                        nbaEntityAttributes: [
                            {
                                nbaEntityAttributeId: 85,
                                name: 'weeklyReferralCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 86,
                                name: 'monthlyReferralCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 87,
                                name: 'yearlyReferralCount',
                                valueOption: null,
                                supportedOperation:
                                    '<,<=,=,>,>=,!=,between_numbers,not_between_numbers',
                                attributeType: 'integer',
                            },
                            {
                                nbaEntityAttributeId: 88,
                                name: 'verifiedByIdMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 89,
                                name: 'verifiedByDataMatch',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 90,
                                name: 'verifiedByFACECAPTURE',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                            {
                                nbaEntityAttributeId: 91,
                                name: 'verifiedByF2F',
                                valueOption: 'true,false',
                                supportedOperation: '==',
                                attributeType: 'boolean',
                            },
                        ],
                    },
                ],
            },
        },
    },
};
