// labels

export const marketLabels = {
    ID: 'INDONESIA',
    PH: 'PHILIPPINES',
    AR: 'ARGENTINA',
};
export const categoryLabels = {
    CC: 'CONVENTIONAL CIGARETTES',
};
export const brandLabels = {
    MARLBORO: 'MARLBORO',
    MAGNUM: 'MAGNUM',
    FORTUNE: 'FORTUNE',
};
