import React, { useEffect, useState } from 'react';
import { conditionFields, EditorConfig } from '../../common/RuleFields';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    Grid,
    IconButton,
    Box,
    Card,
    CardContent,
    Paper,
    Divider,
    FormControl,
    InputLabel,
    CircularProgress,
    Typography,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

//Other Imports
import {
    FIELD_TYPES,
    convertToLabel,
    getInputType,
    ATTRIBUTE_TYPE_BASED_ON_OPERATION,
    OPERATORS,
    INTIAL_AGGREGATOR,
    INTIAL_RULE,
    INTIAL_GROUP,
    getEditorConfig,
} from './utils/utils';
import { RenderDynamicField, RenderAggregatorField } from './utils/Fields';
import { getEntityAttribute } from '../../../services/api/nbaApi';
import { cloneDeep, keyBy } from 'lodash';

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
        {
            transform: 'rotate(0deg)',
        },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
        transform: 'rotate(270deg)',
    },
}));

const WithAccordion =
    ({ title, WrappedContent }) =>
    (props) =>
        (
            <Accordion
                style={{ borderRadius: '4px', marginLeft: '24px' }}
                defaultExpanded
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                >
                    <Box width="100%">
                        <Typography variant="h6" color="textPrimary">
                            {title}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {typeof WrappedContent === 'function' ? (
                        <WrappedContent {...props} />
                    ) : (
                        WrappedContent
                    )}
                </AccordionDetails>
            </Accordion>
        );

// Recursive RuleEditor component
const DynamicRuleEditor = ({
    rulesData = [],
    setData,
    showMainAddGroup = true,
    showNestingAddGroup = true,
    showNestingDelete = false,
    isNestedGrouping = false,
    nestedIdx = 0,
    nestingLength,
    isPublished = false,
}) => {
    const getIntialState = () => {
        return rulesData.length ? rulesData : [{ ...INTIAL_GROUP }];
    };

    const [groups, setGroups] = useState(getIntialState());
    const [entitiesByKeys, setEntitiesByKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const { market, category, brand } = useSelector((store) => store.auth.user);

    useEffect(() => {
        //getEntityAttribute(market)
        //.then((response) => {
        const configData = getEditorConfig(EditorConfig, market, category, brand)
        setEntitiesByKeys((data) => keyBy(configData, 'name'));
        //})
        //.catch((error) => console.log(error))
        // .finally(() => setLoading(false));
    }, [market]);

    // Add a new rule
    const addRule = (groupIdx) => {
        const newGroups = [...groups];
        newGroups[groupIdx].rules = [
            ...newGroups[groupIdx].rules,
            { ...INTIAL_RULE, id: uuidv4() },
        ];
        setGroups(newGroups);
    };

    // Add a new group
    const addGroup = () => {
        setGroups([...groups, { ...INTIAL_GROUP, id: uuidv4() }]);
    };

    //clear dependent Values
    const clearDependentValues = ({ groupIdx, ruleIdx, field }) => {
        let newGroups = [...cloneDeep(groups)];
        switch (field) {
            case FIELD_TYPES.ENTITIES:
                newGroups = [...groups];
                newGroups[groupIdx].aggregator = { ...INTIAL_AGGREGATOR };
                if (!newGroups[groupIdx].rules.length) {
                    break;
                }
                newGroups[groupIdx].rules = newGroups[groupIdx].rules.map(
                    () => {
                        return { ...INTIAL_RULE };
                    }
                );
                break;
            case FIELD_TYPES.ENTITY_ATTRIBUTE:
                newGroups = [...groups];
                newGroups[groupIdx].rules[ruleIdx].attributeValue = null;
                newGroups[groupIdx].rules[ruleIdx].operation = '';
                break;
            case FIELD_TYPES.OPERATION:
                newGroups = [...groups];
                newGroups[groupIdx].rules[ruleIdx].attributeValue = null;
                break;
        }
        return cloneDeep(newGroups);
    };

    // Handle changes in individual rules
    const handleChange = ({ groupIdx, ruleIdx, field, value }) => {
        let newGroups;
        newGroups = clearDependentValues({ groupIdx, ruleIdx, field });
        switch (field) {
            case FIELD_TYPES.ENTITIES:
                newGroups[groupIdx].entity = value;
                break;
            case FIELD_TYPES.ENTITY_ATTRIBUTE:
                newGroups[groupIdx].rules[ruleIdx].entityAttribute = value;
                break;
            case FIELD_TYPES.OPERATION:
                newGroups[groupIdx].rules[ruleIdx].operation = value;
                if (value === 'is_null' || value === 'is_not_null') {
                    newGroups[groupIdx].rules[ruleIdx].attributeValue = 'True';
                }
                break;
            case FIELD_TYPES.ATTRIBUTE:
                newGroups[groupIdx].rules[ruleIdx].attributeValue = value;
                break;
        }
        setGroups(cloneDeep(newGroups));
        setData(cloneDeep(newGroups));
    };

    // Handle operator changes between rules
    const handleOperatorChange = (idx, value) => {
        const newGroups = [...groups];
        newGroups[idx].connector = value;
        setGroups(newGroups);
        setData(newGroups);
    };

    // Delete a rule
    const deleteRule = (groupIdx, ruleId) => {
        const newGroups = [...groups];
        newGroups[groupIdx].rules = newGroups[groupIdx].rules.filter(
            (rule) => rule.id !== ruleId
        );
        setGroups(newGroups);
        setData(newGroups);
    };

    // Delete a group
    const deleteGroup = (groupId) => {
        let newGroups = [...groups];
        // newGroups.splice(groupIdx, 1);
        newGroups = newGroups.filter((group) => group.id !== groupId);
        setGroups(newGroups);
        setData(newGroups);
    };

    const getOptions = ({
        field = FIELD_TYPES.ENTITIES,
        entity = '',
        entityAttribute = '',
        groupIdx,
    }) => {
        if (field === FIELD_TYPES.ENTITIES) {
            return Object.keys(entitiesByKeys); // Return all domain names like 'DEVICE'
        }

        if (field === FIELD_TYPES.ENTITY_ATTRIBUTE && entity) {
            let options =
                entitiesByKeys[entity]?.nbaEntityAttributes?.map((attr) => ({
                    value: attr.name,
                    label: convertToLabel(attr.name),
                })) || [];
            // const rules = groups[groupIdx]?.rules || [];
            // let rulesByEntityAttribute = keyBy(rules, 'entityAttribute');
            // if (rules.length) {
            //   options = options.filter(option => !rulesByEntityAttribute.hasOwnProperty(option.value));
            // }
            return options;
        }

        if (field === FIELD_TYPES.OPERATION && entityAttribute) {
            const attribute = entitiesByKeys[entity]?.nbaEntityAttributes?.find(
                (attr) => attr.name === entityAttribute
            );
            if (attribute?.supportedOperation) {
                return attribute.supportedOperation.split(',');
            }
        }

        if (field === FIELD_TYPES.ATTRIBUTE && entityAttribute) {
            const attribute = entitiesByKeys[entity]?.nbaEntityAttributes?.find(
                (attr) => attr.name === entityAttribute
            );
            if (attribute?.valueOption) {
                return attribute.valueOption.split(',');
            }
        }

        return [];
    };

    const getAttributeValueType = ({ entity, entityAttribute, operation }) => {
        if (entity && entityAttribute && operation) {
            const attribute = entitiesByKeys[entity]?.nbaEntityAttributes?.find(
                (attr) => attr.name === entityAttribute
            );
            return getInputType(
                attribute?.attributeType,
                operation,
                ATTRIBUTE_TYPE_BASED_ON_OPERATION
            );
        } else {
            return 'text';
        }
    };

    const getAttributeType = ({ entity, entityAttribute }) => {
        if (entity && entityAttribute) {
            const attribute = entitiesByKeys[entity]?.nbaEntityAttributes?.find(
                (attr) => attr.name === entityAttribute
            );
            return attribute?.attributeType;
        } else {
            return 'text';
        }
    };

    const showAggregator = (entity) => {
        return entitiesByKeys[entity]?.entityType === 'list' ? true : false;
    };

    const handleAggregatorChange = (groupIdx, value) => {
        const newGroups = [...groups];
        newGroups[groupIdx].aggregator = value;
        setGroups(newGroups);
        setData(newGroups);
    };

    const setNestingData = ({ groupIdx, data, nestedGroupIdx }) => {
        const newGroups = [...groups];
        if (data.length) {
            newGroups[groupIdx].groups[nestedGroupIdx] = { ...data[0] };
        } else {
            if (
                nestedGroupIdx > -1 &&
                nestedGroupIdx < newGroups[groupIdx]?.groups?.length
            ) {
                newGroups[groupIdx]?.groups.splice(nestedGroupIdx, 1);
            }
            if (newGroups[groupIdx]?.groups.length === 1) {
                newGroups[groupIdx] = { ...newGroups[groupIdx]?.groups[0] };
            }
        }
        setGroups(newGroups);
        setData(newGroups);
    };

    const addNestingGroup = (groupIdx) => {
        const newGroups = cloneDeep(groups);
        if (!newGroups[groupIdx].groups.length) {
            const nestedGroups = [{ ...newGroups[groupIdx], id: uuidv4() }];
            // newGroups[groupIdx].groups.push({...newGroups[groupIdx]});
            newGroups[groupIdx] = {
                ...INTIAL_GROUP,
                id: newGroups[groupIdx].id,
            };
            newGroups[groupIdx].groups = nestedGroups;
        }
        const newNestingGroup = { ...INTIAL_GROUP, id: uuidv4() };
        newNestingGroup.rules[0].id = uuidv4();
        newGroups[groupIdx].groups.push(newNestingGroup);
        setGroups(newGroups);
    };

    const getGroupTitle = (idx) => {
        return '';
        if (isNestedGrouping) {
            return nestedIdx + 1 < 10 ? `0${nestedIdx + 1}` : nestedIdx + 1;
        } else {
            return idx + 1 < 10 ? `0${idx + 1}` : idx + 1;
        }
    };

    const getAttributeTypeDisabled = (operation = null) => {
        if (isPublished) {
            return true;
        }
        if (!operation) {
            return true;
        } else {
            if (operation === 'is_null' || operation === 'is_not_null') {
                return true;
            } else {
                return false;
            }
        }
    };

    const renderRules = ({ group, index }) => {
        const showHelperText = (type) => type === '2-input-1-dropdown';
        return (
            <>
                <Box
                    sx={{ padding: 2, marginBottom: 2 }}
                    bgcolor={'#f2f7f9'}
                    borderRadius={'4px'}
                >
                    {/* <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}> */}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <RenderDynamicField
                                label="Entity:"
                                type={'select'}
                                intialValue={group.entity}
                                handleBlur={(value) =>
                                    handleChange({
                                        groupIdx: index,
                                        field: FIELD_TYPES.ENTITIES,
                                        value,
                                    })
                                }
                                values={getOptions({
                                    field: FIELD_TYPES.ENTITIES,
                                })}
                                id={group.id}
                                disabled={isPublished}
                                // clearDependentValues={() => clearDependentValues({groupIdx: index, field: FIELD_TYPES.ENTITIES})}
                            />
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                </Box>
                {group.rules.map((rule, idx) => (
                    <Box
                        sx={{ padding: 2, marginBottom: 2 }}
                        bgcolor={'#f2f7f9'}
                        borderRadius={'4px'}
                    >
                        {/* <Paper key={idx} elevation={2} sx={{ padding: 2, marginBottom: 2 }}> */}
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <RenderDynamicField
                                    id={rule.id}
                                    label="Event Attribute is:"
                                    type={'select'}
                                    intialValue={rule.entityAttribute}
                                    handleBlur={(value) =>
                                        handleChange({
                                            groupIdx: index,
                                            ruleIdx: idx,
                                            field: FIELD_TYPES.ENTITY_ATTRIBUTE,
                                            value,
                                        })
                                    }
                                    values={getOptions({
                                        field: FIELD_TYPES.ENTITY_ATTRIBUTE,
                                        entity: group.entity,
                                        groupIdx: index,
                                    })}
                                    disabled={
                                        isPublished ? true : !group.entity
                                    }
                                    // clearDependentValues={() => clearDependentValues({groupIdx: index, field: FIELD_TYPES.ENTITY_ATTRIBUTE,  ruleIdx: idx})}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    key={idx}
                                    disabled={
                                        isPublished
                                            ? true
                                            : !rule.entityAttribute
                                    }
                                >
                                    <InputLabel
                                        id="dynamicOperationLabel"
                                        className="dynamicFieldLabel"
                                        shrink
                                        sx={{color: 'black', backgroundColor: 'white'}}
                                    >
                                        {'Operation'}
                                    </InputLabel>
                                    <Select
                                        labelId="dynamicOperationLabel"
                                        value={rule.operation}
                                        onChange={(e) =>
                                            handleChange({
                                                groupIdx: index,
                                                ruleIdx: idx,
                                                field: FIELD_TYPES.OPERATION,
                                                value: e.target.value,
                                            })
                                        }
                                        // clearDependentValues={() => clearDependentValues({groupIdx: index, ruleIdx: idx, field: FIELD_TYPES.OPERATION})}
                                        fullWidth
                                    >
                                        {getOptions({
                                            field: FIELD_TYPES.OPERATION,
                                            entity: group.entity,
                                            entityAttribute:
                                                rule.entityAttribute,
                                        }).map((operator) => (
                                            <MenuItem
                                                key={operator}
                                                value={operator}
                                            >
                                                {convertToLabel(operator)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <RenderDynamicField
                                id={rule.id + 1}
                                label="Attribute Value is:"
                                type={getAttributeValueType({
                                    entity: group.entity,
                                    entityAttribute: rule.entityAttribute,
                                    operation: rule.operation,
                                })}
                                intialValue={rule.attributeValue}
                                handleBlur={(value) =>
                                    handleChange({
                                        groupIdx: index,
                                        ruleIdx: idx,
                                        field: FIELD_TYPES.ATTRIBUTE,
                                        value,
                                    })
                                }
                                values={getOptions({
                                    field: FIELD_TYPES.ATTRIBUTE,
                                    entity: group.entity,
                                    entityAttribute: rule.entityAttribute,
                                })}
                                // disabled={rule.operation ? false : true}
                                disabled={getAttributeTypeDisabled(
                                    rule.operation
                                )}
                                addGrid={true}
                                attributeType={getAttributeType({
                                    entity: group.entity,
                                    entityAttribute: rule.entityAttribute,
                                })}
                                operation={rule.operation}
                            />
                            <Grid
                                item
                                xs={1}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <IconButton
                                    onClick={() => deleteRule(index, rule.id)}
                                    sx={{ color: '#c8102e' }}
                                    disabled={isPublished}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {showHelperText(
                                    getAttributeValueType({
                                        entity: group.entity,
                                        entityAttribute: rule.entityAttribute,
                                        operation: rule.operation,
                                    })
                                ) && (
                                    <Tooltip
                                        title={
                                            <>
                                                <Typography>
                                                    1. For past period, provide
                                                    negative values.
                                                </Typography>
                                                <Typography>
                                                    2. For future period,
                                                    provide positive values.
                                                </Typography>
                                            </>
                                        }
                                        arrow
                                    >
                                        <HelpIcon />
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                        {/* </Paper> */}
                        {/* {idx < group.rules.length - 1 && (
                <Box display="flex" justifyContent="left" alignItems="center" sx={{ marginBottom: 2 }}>
                  <Select
                      value={'&&'}
                      onChange={(e) => handleOperatorChange(index, e.target.value)}
                      disabled={true}
                  >
                      {OPERATORS.map(({label, value}, idx) => (
                          <MenuItem key={idx} value={value}>
                              {label}
                          </MenuItem>
                      ))}
                  </Select>
                </Box>
            )} */}
                    </Box>
                ))}
                <Box sx={{ marginTop: 2 }} display={'flex'}>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => addRule(index)}
                            style={{ paddingLeft: '8px' }}
                            disabled={isPublished}
                        >
                            <AddIcon /> Add
                        </Button>
                    </Box>
                </Box>
                {showAggregator(group.entity) && (
                    <Box
                        sx={{ padding: 2, marginBottom: 2, marginTop: 2 }}
                        bgcolor={'#f2f7f9'}
                        borderRadius={'4px'}
                    >
                        {/* <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}> */}
                        <Grid container spacing={2} alignItems="center">
                            <RenderAggregatorField
                                value={group.aggregator}
                                rules={group.rules}
                                id={group.id}
                                handleChange={(value) =>
                                    handleAggregatorChange(index, value)
                                }
                                disabled={isPublished}
                            />
                        </Grid>
                        {/* </Paper> */}
                    </Box>
                )}
                {showNestingDelete && nestedIdx > 0 && (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => deleteGroup(group.id)}
                            sx={{ ml: 2 }}
                            disabled={isPublished}
                        >
                            Delete Rule
                        </Button>
                    </Box>
                )}
            </>
        );
    };

    // const RulesAccordion = WithAccordion({
    //   title: "Rule 01",
    //   WrappedContent: RenderRules
    // });

    const generateRules = ({ group, index }) => {
        if (!isNestedGrouping) {
            return (
                <Accordion
                    key={group.id}
                    style={{
                        backgroundColor: '#ffffff',
                        borderRadius: '4px',
                        marginLeft: '24px',
                    }}
                    defaultExpanded
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon fontSize="large" />}
                    ></AccordionSummary>
                    <AccordionDetails>
                        {renderRules({ group, index })}
                    </AccordionDetails>
                </Accordion>
            );
        } else {
            return renderRules({ group, index });
        }
    };

    return (
        <>
            {loading ? (
                <Box display="flex" alignItems="center" width="100%" mb={2}>
                    <CircularProgress size={24} />
                    <Typography variant="body2" ml={2}>
                        Loading Entities...
                    </Typography>
                </Box>
            ) : (
                <>
                    {groups.map((group, index) => (
                        <>
                            <Accordion
                                key={group.id}
                                style={{
                                    backgroundColor: isNestedGrouping
                                        ? '#ffffff'
                                        : '#e5f0f6',
                                    border: isNestedGrouping
                                        ? 'none'
                                        : '1px solid #8fb8f9',
                                    borderRadius: '4px',
                                    marginLeft: '24px',
                                }}
                                defaultExpanded
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon fontSize="large" />
                                    }
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        width={'100%'}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="textPrimary"
                                        >{`${
                                            isNestedGrouping
                                                ? 'Rule'
                                                : 'Rule Group'
                                        } ${getGroupTitle(index)}`}</Typography>
                                        {index >= 1 && (
                                            <Button
                                                variant="text"
                                                color="error"
                                                onClick={() =>
                                                    deleteGroup(group.id)
                                                }
                                                disabled={isPublished}
                                            >
                                                Delete Group
                                            </Button>
                                        )}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        //   <Accordion style={{ borderRadius: '4px'}} defaultExpanded>
                                        //     <AccordionSummary
                                        //       expandIcon={<ExpandMoreIcon fontSize='large' />}
                                        //     >
                                        //       <Box width={'100%'}>
                                        //         <Typography variant='h6' color='textPrimary'>{'Rule 01'}</Typography>
                                        //       </Box>
                                        //     </AccordionSummary>
                                        //     <AccordionDetails>

                                        //   </AccordionDetails>
                                        // </Accordion>
                                        !group.groups.length > 0 &&
                                            generateRules({ group, index })
                                    }
                                    {group.groups.length > 0 &&
                                        group.groups.map(
                                            (nestedGroup, nestedIdx) => (
                                                <Box mt={2}>
                                                    <DynamicRuleEditor
                                                        rulesData={[
                                                            nestedGroup,
                                                        ]}
                                                        setData={(data) =>
                                                            setNestingData({
                                                                groupIdx: index,
                                                                data,
                                                                nestedGroupIdx:
                                                                    nestedIdx,
                                                            })
                                                        }
                                                        showMainAddGroup={false}
                                                        showNestingAddGroup={
                                                            false
                                                        }
                                                        // isNestingOperator={nestedIdx === 0 ? false : true}
                                                        showNestingDelete={true}
                                                        isNestedGrouping={true}
                                                        nestedIdx={nestedIdx}
                                                        nestingLength={
                                                            group.groups.length
                                                        }
                                                        isPublished={
                                                            isPublished
                                                        }
                                                    />
                                                </Box>
                                            )
                                        )}
                                    {showNestingAddGroup && (
                                        <Box mt={2} display={'flex'}>
                                            <Box>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() =>
                                                        addNestingGroup(index)
                                                    }
                                                    sx={{ mr: 2, pl: 1 }}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        color: '#000',
                                                    }}
                                                    disabled={isPublished}
                                                >
                                                    <AddIcon /> Add Rule
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            {isNestedGrouping &&
                                nestedIdx + 1 !== nestingLength && (
                                    <Box
                                        display="flex"
                                        justifyContent="left"
                                        alignItems="center"
                                        sx={{ marginBottom: 2, marginTop: 2 }}
                                    >
                                        <Select
                                            value={group.connector || '&&'}
                                            onChange={(e) =>
                                                handleOperatorChange(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                            className="nested-operator-select"
                                            disabled={isPublished}
                                        >
                                            {OPERATORS.map(
                                                ({ label, value }, idx) => (
                                                    <MenuItem
                                                        key={idx}
                                                        value={value}
                                                    >
                                                        {label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </Box>
                                )}
                            {index < groups.length - 1 && (
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="center"
                                    sx={{ marginBottom: 2, marginTop: 2 }}
                                >
                                    <Select
                                        value={group.connector || '&&'}
                                        onChange={(e) =>
                                            handleOperatorChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        className="operator-select"
                                        disabled={isPublished}
                                    >
                                        {OPERATORS.map(
                                            ({ label, value }, idx) => (
                                                <MenuItem
                                                    key={idx}
                                                    value={value}
                                                >
                                                    {label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </Box>
                            )}
                        </>
                    ))}
                    {/* {!isNestingOperator && <Divider sx={{ marginY: 2 }} />} */}
                    {showMainAddGroup && (
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#e5f0f6',
                                    color: '#000',
                                    border: '1px solid #8fb8f9',
                                }}
                                onClick={addGroup}
                                sx={{ pl: 1 }}
                                disabled={isPublished}
                            >
                                <AddIcon /> Add Rule Group
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default DynamicRuleEditor;
