import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Select,
    MenuItem,
    IconButton,
    Box,
    CircularProgress,
    Typography,
    InputLabel,
    FormControl,
    Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getActionList } from '../../../services/api/nbaApi';
import { useSelector } from 'react-redux';
import { asString } from '../../../services/typeUtils';

const ActionCreator = ({ actionsData, setData, isPublished }) => {
    const [actions, setActions] = useState(actionsData);
    //const [availableActions, setAvailableActions] = useState(actionsList.data);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { market } = useSelector((store) => store.auth.user);

    // Fetch action list only once
    // useEffect(() => {
    //     //getActionList(market)
    //        // .then((response) => {
    //            // setAvailableActions(response.data);
    //             setLoading(false);
    //         })
    //         .catch((err) => {
    //             setError('Failed to fetch actions');
    //             setLoading(false);
    //         });
    // }, [market]);

    // Sync actions with parent state
    useEffect(() => {
        setData(actions); // Update parent component's state whenever actions change
    }, [actions, setData]);

    const handleActionChange = (updatedAction) => {
        // const updatedActions = actions.map((action, i) =>
        //     i === index ? updatedAction : action
        // );
        setActions(updatedAction);
    };

    const handleAddAction = () => {
        setActions([...actions, { domain: '', attributeName: '', operation: '', attributeValue: '' }]);
    };

    const handleDeleteAction = (index) => {
        const updatedActions = actions.filter((_, i) => i !== index);
        setActions(updatedActions);
    };

    return (
        <>
            <Box
                p={4}
                mx="auto"
                sx={{
                    padding: 2,
                    marginBottom: 3,
                    boxShadow: 3,
                    backgroundColor: '#e5f0f6',
                    borderRadius: '4px',
                }}
            >
                {/* {actions.map((action, index) => ( */}
                <Action
                    //key={index}
                    //index={index}
                    actionData={actionsData}
                    onActionChange={handleActionChange}
                    onDeleteAction={handleDeleteAction}
                    //availableActions={availableActions}
                    loading={loading}
                    error={error}
                    disabled={isPublished}
                />
                {/* ))} */}
            </Box>
            <Box mt={2}>
                <Button
                    color="primary"
                    onClick={handleAddAction}
                    style={{
                        backgroundColor: '#e5f0f6',
                        color: '#000',
                        border: '1px solid #8fb8f9',
                    }}
                    disabled={isPublished}
                >
                    <AddIcon /> Add Action
                </Button>
            </Box>
        </>
    );
};

const Action = ({
    index,
    actionData,
    onActionChange,
    onDeleteAction,
    availableActions,
    loading,
    error,
    disabled,
}) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onActionChange({ ...actionData, [name]: value });
    };

    return (
        <Paper elevation={2} sx={{ margin: (theme) => theme.spacing(2) }}>
            <Box display="flex" alignItems="center" gap={2} my={2} p={2}>
                {loading ? (
                    <Box display="flex" alignItems="center" width="100%">
                        <CircularProgress size={24} />
                        <Typography variant="body2" ml={2}>
                            Loading actions...
                        </Typography>
                    </Box>
                ) : error ? (
                    <Typography variant="body2" color="error">
                        {error}
                    </Typography>
                ) : (
                    <>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel
                                sx={{ color: '#000', backgroundColor: '#fff' }}
                                shrink
                            >
                                Entity
                            </InputLabel>
                            <Select
                                name="domain"
                                value={actionData.domain}
                                onChange={handleInputChange}
                                displayEmpty
                                fullWidth
                            >
                                {/* // {availableActions.map((action) => {return ( */}
                                <MenuItem value="Consumer">Consumer</MenuItem>
                                {/* //     );
                                // })} */}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel
                                sx={{ color: '#000', backgroundColor: '#fff' }}
                                shrink
                            >
                                Event Attribute is
                            </InputLabel>
                            <Select
                                name="attributeName"
                                value={actionData.attributeName}
                                onChange={handleInputChange}
                                displayEmpty
                                fullWidth
                            >
                                {/* // {availableActions.map((action) => {return ( */}
                                <MenuItem value="points">Points</MenuItem>
                                {/* //     );
                                // })} */}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel
                                sx={{ color: '#000', backgroundColor: '#fff' }}
                                shrink
                            >
                                Operation
                            </InputLabel>
                            <Select
                                name="operation"
                                value={actionData.operation}
                                onChange={handleInputChange}
                                displayEmpty
                                fullWidth
                            >
                                {/* // {availableActions.map((action) => {return ( */}
                                <MenuItem value="==">==</MenuItem>
                                {/* //     );
                                // })} */}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ color: '#000', backgroundColor: '#fff' }}
                                shrink
                            >
                                Attribute Value is
                            </InputLabel>
                            <TextField
                                name="attributeValue"
                                type="number"
                                value={actionData.attributeValue}
                                onChange={(e) => {
                                    // if (e.target.value <= 0) {
                                    //     return;
                                    // }
                                    handleInputChange(e);
                                }}
                                fullWidth
                                disabled={disabled}
                            />
                        </FormControl>

                        <IconButton
                            aria-label="delete"
                            onClick={() => onDeleteAction(index)}
                            sx={{ color: '#c8102e' }}
                            disabled={disabled}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </>
                )}
            </Box>
        </Paper>
    );
};

export default ActionCreator;
