import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { Provider as ReduxProvider } from 'react-redux';
import { enIN } from 'date-fns/locale/en-IN';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';

import App from './App';
import config from './config';
import configureStore from './store/configureStore';
import './index.css';
import './styles/sass/index.scss';

Amplify.configure(config);
const store = configureStore();

ReactDOM.render(
    <ReduxProvider store={store}>
        <React.StrictMode>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enIN}
            >
                <App />
            </LocalizationProvider>
        </React.StrictMode>
    </ReduxProvider>,
    document.getElementById('root')
);
