import React, { useEffect, useState } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    Grid,
    FormControl,
    InputLabel,
    Box,
    FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { convertToLabel, isObjectAndNotEmpty } from './utils';

const UNITS = ['DAYS', 'MONTHS', 'QUARTERS'];

const UNIT = ['DAY', 'MONTH', 'QUARTER'];

const RenderDynamicDateBasedOnOperator = ({
    type,
    handleBlur = () => {},
    intialValue,
    operation,
    disabled,
}) => {
    const checkEmptyAndGetValue = () => {
        if (isObjectAndNotEmpty(intialValue)) {
            return intialValue;
        } else {
            return {
                dateUnit: '',
                startValue: 0,
                endValue: 0,
                fromInputDate: '',
                toInputDate: '',
                givenDate: '',
            };
        }
    };

    const [date, setDate] = useState(checkEmptyAndGetValue());

    useEffect(() => {
        setDate(checkEmptyAndGetValue());
    }, [operation]);

    const DATE_ATTR = {
        DATE_UNIT: 'dateUnit',
        START_VALUE: 'startValue',
        END_VALUE: 'endValue',
        FROM_INPUT_DATE: 'fromInputDate',
        TO_INPUT_DATE: 'toInputDate',
        GIVEN_DATE: 'givenDate',
    };

    const handleDateChange = ({ attr, value }) => {
        const newValue = { ...date };
        newValue[attr] = value;
        setDate(newValue);
        handleBlur(newValue);
    };

    const handleChange = ({ attr, value }) => {
        const newValue = { ...date };
        newValue[attr] = value;
        setDate(newValue);
        handleBlur(newValue);
    };

    const handleDateBlur = ({ attr, value }) => {
        const newValue = { ...date };
        newValue[attr] = value;
        handleBlur(newValue);
    };

    const getIsFromDisabled = () => {
        return operation === 'WITHIN_NEXT' || operation === 'NOT_WITHIN_NEXT';
    };

    const getIsToDisabled = () => {
        return operation === 'WITHIN_PAST' || operation === 'NOT_WITHIN_PAST';
    };

    switch (type) {
        case '2-dates':
            return (
                <>
                    <Grid item xs={3}>
                        <DatePicker
                            label={<Box color={'#000'}>Start Date</Box>}
                            value={date.fromInputDate}
                            onChange={(date) =>
                                handleDateChange({
                                    attr: DATE_ATTR.FROM_INPUT_DATE,
                                    value: date,
                                })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            label={<Box color={'#000'}>End Date</Box>}
                            value={date.toInputDate}
                            onChange={(date) =>
                                handleDateChange({
                                    attr: DATE_ATTR.TO_INPUT_DATE,
                                    value: date,
                                })
                            }
                            renderInput={(params) => <TextField {...params} />}
                            disabled={disabled}
                        />
                    </Grid>
                </>
            );
        case 'date':
            return (
                <Grid item xs={3}>
                    <DatePicker
                        label={<Box color={'#000'}>Date</Box>}
                        value={date.givenDate}
                        onChange={(date) =>
                            handleDateChange({
                                attr: DATE_ATTR.GIVEN_DATE,
                                value: date,
                            })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        disabled={disabled}
                    />
                </Grid>
            );
        case '2-input-1-dropdown':
            return (
                <>
                    <Grid item xs={2}>
                        <FormControl disabled={disabled}>
                            <InputLabel className="dynamicFieldLabel" shrink>
                                {'From'}
                            </InputLabel>
                            <TextField
                                // label='From'
                                type="number"
                                value={date.startValue}
                                onChange={(e) =>
                                    handleChange({
                                        attr: DATE_ATTR.START_VALUE,
                                        value: e.target.value,
                                    })
                                }
                                disabled={getIsFromDisabled()}
                                // onBlur={(e) => handleDateBlur({attr: DATE_ATTR.START_VALUE, value: e.target.value})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl disabled={disabled}>
                            <InputLabel className="dynamicFieldLabel" shrink>
                                {'To'}
                            </InputLabel>
                            <TextField
                                // label='To'
                                type="number"
                                value={date.endValue}
                                onChange={(e) =>
                                    handleChange({
                                        attr: DATE_ATTR.END_VALUE,
                                        value: e.target.value,
                                    })
                                }
                                // onBlur={(e) => handleDateBlur({attr: DATE_ATTR.END_VALUE, value: e.target.value})}
                                disabled={getIsToDisabled()}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel
                                id="dynamicUnitSelectLabel"
                                className="dynamicFieldLabel"
                                shrink
                            >
                                {'Unit'}
                            </InputLabel>
                            <Select
                                labelId="dynamicUnitSelectLabel"
                                value={date.dateUnit}
                                onChange={(e) =>
                                    handleChange({
                                        attr: DATE_ATTR.DATE_UNIT,
                                        value: e.target.value,
                                    })
                                }
                                // onBlur={(e) => handleDateBlur({attr: DATE_ATTR.DATE_UNIT, value: e.target.value})}
                            >
                                {UNITS.map((unit) => (
                                    <MenuItem key={unit} value={unit}>
                                        {convertToLabel(unit)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            );
        case '2-number':
            return (
                <Grid container item xs={6} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel className="dynamicFieldLabel" shrink>
                                {'From'}
                            </InputLabel>
                            <TextField
                                // label='From'
                                type="number"
                                value={Number(date.startValue)}
                                onChange={(e) => {
                                    if (e.target.value < 0) {
                                        return;
                                    }
                                    handleChange({
                                        attr: DATE_ATTR.START_VALUE,
                                        value: Number(e.target.value),
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} disabled={disabled}>
                        <FormControl fullWidth>
                            <InputLabel className="dynamicFieldLabel" shrink>
                                {'To'}
                            </InputLabel>
                            <TextField
                                // label='To'
                                type="number"
                                value={Number(date.endValue)}
                                onChange={(e) => {
                                    if (e.target.value < 0) {
                                        return;
                                    }
                                    handleChange({
                                        attr: DATE_ATTR.END_VALUE,
                                        value: Number(e.target.value),
                                    });
                                }}
                                // onBlur={(e) => handleDateBlur({attr: DATE_ATTR.END_VALUE, value: e.target.value})}
                            />
                        </FormControl>
                    </Grid>
                    {/* {((date.startValue > 0) && (date.startValue >= date.endValue)) && (
              <Grid item xs={12}>
                <FormHelperText style={{color: 'red'}}>
                  Start value should be less than the end value
                </FormHelperText>
              </Grid>
          )} */}
                </Grid>
            );
        case 'range_select':
            return (
                <>
                    <Grid item xs={2}>
                        <FormControl fullWidth disabled={disabled}>
                            <Select
                                value={date.dateUnit}
                                onChange={(e) =>
                                    handleChange({
                                        attr: DATE_ATTR.DATE_UNIT,
                                        value: e.target.value,
                                    })
                                }
                                // onBlur={(e) => handleDateBlur({attr: DATE_ATTR.DATE_UNIT, value: e.target.value})}
                            >
                                <MenuItem value="" disabled>
                                    Select Option
                                </MenuItem>
                                {UNIT.map((unit) => (
                                    <MenuItem key={unit} value={unit}>
                                        {convertToLabel(unit)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            );
        default:
            return null;
    }
};

export const RenderDynamicField = ({
    type = 'text',
    intialValue = '',
    handleBlur,
    values,
    label = 'Attribute Value Is:',
    id,
    disabled = false,
    addGrid = false,
    attributeType,
    operation,
    clearDependentValues,
}) => {
    let addAGrid = addGrid;
    let field = null;

    // const [value, setValue] = useState("");

    // useEffect(() => {
    //   setValue(intialValue)
    // }, [intialValue])

    const generateOptions = (options) => {
        let optionsToReturn = [...options];
        if (
            !options.length &&
            (attributeType === 'boolean' ||
                operation === 'is_null' ||
                operation === 'is_not_null')
        ) {
            optionsToReturn = ['True', 'False'];
        }
        return optionsToReturn?.map((option, index) => {
            let value = '';
            let label = '';
            if (typeof option === 'string') {
                value = option;
                label = option === 'profile' ? "consumer" : option;
            } else {
                value = option.value;
                label = option.label;
            }
            return (
                <MenuItem key={index} value={value}>
                    {convertToLabel(label)}
                </MenuItem>
            );
        });
    };

    const handleChange = (e) => {
        e.preventDefault();
        // setValue(e.target.value);
        handleBlur(e.target.value);
    };

    switch (type) {
        case 'number':
            field = (
                <FormControl fullWidth key={id} disabled={disabled}>
                    <InputLabel
                        labelId="dynamicNumberLabel"
                        className="dynamicFieldLabel"
                        shrink
                        sx={{color: 'black', backgroundColor: 'white'}}
                    >
                        {label}
                    </InputLabel>
                    <TextField
                        id="dynamicNumberLabel"
                        type="number"
                        value={intialValue}
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                return;
                            }
                            handleChange(e);
                        }}
                        // onBlur={(e) => handleBlur(e.target.value)}
                        fullWidth
                    />
                </FormControl>
            );
            break;
        case 'select':
            field = (
                <FormControl fullWidth key={id} disabled={disabled}>
                    <InputLabel
                        id="dynamicSelectLabel"
                        className="dynamicFieldLabel"
                        shrink
                        sx={{color: 'black', backgroundColor: 'white'}}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        labelId="dynamicSelectLabel"
                        id="dynamicSelect"
                        value={intialValue}
                        label={label}
                        placeholder={label}
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="" disabled={true}>
                            Select Option
                        </MenuItem>
                        {generateOptions(values)}
                    </Select>
                </FormControl>
            );
            break;
        case 'multi-select':
            field = (
                <FormControl fullWidth key={id} disabled={disabled}>
                    <InputLabel
                        id="dynamicMultiSelectLabel"
                        className="dynamicFieldLabel"
                        shrink
                        sx={{ color: 'black', backgroundColor: 'white' }}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        sx={{ color: 'black' }}
                        labelId="dynamicMultiSelectLabel"
                        id="dynamicMultiSelect"
                        multiple
                        value={
                            (typeof intialValue === 'string'
                                ? intialValue?.split(',')
                                : intialValue) || []
                        }
                        onChange={(e) => handleChange(e)}
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Select Option
                        </MenuItem>
                        {generateOptions(values)}
                    </Select>
                </FormControl>
            );
            break;
        case 'date':
        case '2-dates':
        case '2-input-1-dropdown':
        case 'range_select':
        case '2-number':
            addAGrid = false;
            field = RenderDynamicDateBasedOnOperator({
                type,
                handleBlur,
                intialValue,
                operation,
                disabled,
            });
            break;
        case 'text':
            field = (
                <FormControl fullWidth key={id}>
                    <InputLabel
                        id="dynamicTextLabel"
                        className="dynamicFieldLabel"
                        shrink
                        sx={{color: 'black', backgroundColor: 'white'}}
                    >
                        {label}
                    </InputLabel>
                    <TextField
                        // label={label}
                        id="dynamicTextLabel"
                        disabled={disabled}
                        type="text"
                        value={intialValue}
                        onChange={(e) => handleChange(e)}
                        // onBlur={(e) => handleBlur(e.target.value)}
                        fullWidth
                    />
                </FormControl>
            );
            break;
    }

    if (addAGrid) {
        return (
            <Grid item xs={3}>
                {field}
            </Grid>
        );
    } else {
        return field;
    }
};

export const RenderAggregatorField = ({
    value,
    handleChange,
    rules = [],
    disabled,
}) => {
    const [aggregatorState, setAggregatorState] = useState(value);

    useEffect(() => {
        setAggregatorState(value);
    }, [value]);

    const options = [
        'first',
        'exactly',
        'greater',
        'lesser',
        'atleast',
        'utmost',
    ];
    const orderOptions = ['asc', 'desc'];

    const FIELDS = {
        AGGREGATOR: 'countOperation',
        ON: 'orderAttributeName',
        ORDER_BY: 'orderBy',
        NUMERIC: 'countValue',
    };

    const handleAggregatorChange = (value, field) => {
        const newAggregatorState = { ...aggregatorState };
        if (
            aggregatorState.countOperation === 'first' ||
            aggregatorState.countOperation === 'last'
        ) {
            newAggregatorState.countValue = '';
        } else {
            newAggregatorState.orderAttributeName = '';
            newAggregatorState.orderBy = '';
        }
        newAggregatorState[field] = value;
        setAggregatorState(newAggregatorState);
        handleChange(newAggregatorState);
    };

    const getOptions = () => {
        if (!rules.length) {
            return [];
        }

        return rules?.map((rule) => {
            return rule.entityAttribute;
        });
    };

    return (
        <>
            <Grid item xs={3}>
                <FormControl fullWidth disabled={disabled}>
                    <InputLabel
                        id="aggregator"
                        style={{ color: '#000', backgroundColor: '#fff' }}
                        shrink
                    >
                        {'Aggregator'}
                    </InputLabel>
                    <Select
                        labelId="aggregator"
                        value={aggregatorState.countOperation}
                        onChange={(e) =>
                            handleAggregatorChange(
                                e.target.value,
                                FIELDS.AGGREGATOR
                            )
                        }
                        variant="outlined"
                    >
                        <MenuItem value="" disabled>
                            Select Option
                        </MenuItem>
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option === 'first'
                                    ? 'Order By'
                                    : convertToLabel(option)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {aggregatorState.countOperation === 'first' ||
            aggregatorState.countOperation === 'last' ? (
                <>
                    <Grid item xs={3}>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel
                                id="on"
                                style={{
                                    color: '#000',
                                    backgroundColor: '#fff',
                                }}
                                shrink
                            >
                                {'On'}
                            </InputLabel>
                            <Select
                                value={aggregatorState.orderAttributeName}
                                onChange={(e) =>
                                    handleAggregatorChange(
                                        e.target.value,
                                        FIELDS.ON
                                    )
                                }
                                variant="outlined"
                                labelId="On"
                            >
                                <MenuItem value="" disabled>
                                    Select Option
                                </MenuItem>
                                {getOptions()?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {convertToLabel(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth disabled={disabled}>
                            <InputLabel
                                id="order"
                                style={{
                                    color: '#000',
                                    backgroundColor: '#fff',
                                }}
                                shrink
                            >
                                {'Order'}
                            </InputLabel>
                            <Select
                                labelId="order"
                                value={aggregatorState.orderBy}
                                onChange={(e) =>
                                    handleAggregatorChange(
                                        e.target.value,
                                        FIELDS.ORDER_BY
                                    )
                                }
                                variant="outlined"
                            >
                                <MenuItem value="" disabled>
                                    Select Option
                                </MenuItem>
                                {orderOptions?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {convertToLabel(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            ) : (
                <Grid item xs={3}>
                    <FormControl>
                        <InputLabel
                            style={{ color: '#000', backgroundColor: '#fff' }}
                            shrink
                        >
                            {'Enter Value'}
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            placeholder="Enter Value"
                            type="number"
                            value={aggregatorState.countValue}
                            onChange={(e) => {
                                if (e.target.value < 0) {
                                    return;
                                }
                                handleAggregatorChange(
                                    e.target.value,
                                    FIELDS.NUMERIC
                                );
                            }}
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid>
            )}
        </>
    );
};
