import { format, toDate } from 'date-fns';
import keyBy from 'lodash/keyBy';
import { v4 as uuidv4 } from 'uuid';

// Logical operators
export const OPERATORS = [
    { label: 'AND', value: '&&' },
    { label: 'OR', value: '||' } /* , {label:'NOT', value: '!'} */,
];

export const INTIAL_AGGREGATOR = {
    countOperation: '',
    countValue: '',
    orderAttributeName: '',
    orderBy: '',
};

export const INTIAL_RULE = {
    entityAttribute: '',
    attributeValue: null,
    operation: '',
};

export const INTIAL_GROUP = {
    rules: [
        {
            ...INTIAL_RULE,
            id: uuidv4(),
        },
    ],
    id: uuidv4(),
    entity: '',
    connector: '&&',
    aggregator: { ...INTIAL_AGGREGATOR },
    groups: [],
};

export const FIELD_TYPES = {
    ENTITIES: 'ENTITIES',
    ENTITY_ATTRIBUTE: 'ENTITY_ATTRIBUTE',
    OPERATION: 'OPERATION',
    ATTRIBUTE: 'ATTRIBUTE',
};

export const ATTRIBUTE_TYPE_BASED_ON_OPERATION = [
    { attributeType: 'boolean', operations: ['==', '!='], inputType: 'select' },
    {
        attributeType: 'integer',
        operations: ['<', '<=', '=', '>', '>=', '!='],
        inputType: 'number',
    },
    {
        attributeType: 'integer',
        operations: ['between_numbers', 'not_between_numbers'],
        inputType: '2-number',
    },
    {
        attributeType: 'string',
        operations: [
            'starts_with',
            'ends_with',
            'not_starts_with',
            'not_ends_with',
            'equals',
            'not_equals',
            'contains',
            'not_contains',
            'is_null',
            'is_not_null',
            '=',
        ],
        inputType: 'text',
    },
    {
        attributeType: 'string',
        operations: ['is_multiple', 'is_not_multiple'],
        inputType: 'multi-select',
    },
    {
        attributeType: 'string',
        operations: ['=='],
        inputType: 'select',
    },
    {
        attributeType: 'date',
        operations: [
            'after',
            'before',
            'on_or_after',
            'on_or_before',
            'on',
            'not_on',
        ],
        inputType: 'date',
    },
    {
        attributeType: 'date_range',
        operations: ['between_dates', 'not_between_dates'],
        inputType: '2-dates',
    },
    {
        attributeType: 'dynamic_date_range',
        operations: ['between_period', 'not_between_period'],
        inputType: '2-input-1-dropdown',
    },
    {
        attributeType: 'dynamic_range',
        operations: ['within_this', 'not_within_this'],
        inputType: 'range_select',
    },
];

export const KEY_BY_ATTRIBUTE_TYPE_BASED_ON_OPERATION = keyBy(
    ATTRIBUTE_TYPE_BASED_ON_OPERATION,
    'attributeType'
);

export const convertToLabel = (text) => {
    return text
        .split('_') // Split the string by underscores
        .map((word) => {
            // Capitalize the first letter of each word
            //return word.charAt(0).toUpperCase() + word.slice(1);
            return word.toUpperCase();
        })
        .join(' '); // Join the words with spaces
};

// export const ENTITIES_BY_KEYS = keyBy(ENTITIES, 'name');

export const getInputType = (
    attributeType,
    operation,
    attributeTypeBasedOnOperation
) => {
    // Define a list of attribute types to search if the attribute_type is 'date'
    const attributeTypesToSearch =
        attributeType === 'date'
            ? ['date', 'date_range', 'dynamic_date_range', 'dynamic_range']
            : [attributeType?.trim()];

    // Find the matching attribute_type and operation
    for (let item of attributeTypeBasedOnOperation) {
        if (
            attributeTypesToSearch.includes(item.attributeType) &&
            item.operations.includes(operation.trim())
        ) {
            return item.inputType;
        }
    }

    // Return null if no match found
    return attributeType;
};

export const getInputTypeByOperation = (
    operation,
    attributeTypeBasedOnOperation
) => {
    for (let item of attributeTypeBasedOnOperation) {
        if (item.operations.includes(operation)) {
            return item.inputType;
        }
    }
    return 'text';
};

// const OPERATION_MAPPER = {
//   Equals: 'equals',
//   Notequals: 'notequals',
//   Ismultiple:
// }

const MVEL_EXPRESSION_MAPPER = {
    equals: {
        key: 'equals',
        regex: /^consumer\.(\w+)\.(\w+)\.equals\("([^"]+)"\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${entityAttribute}.equals("${attributeValue}")`
                : `consumer.${entityAttribute}.equals("${attributeValue}")`,
    },
    not_equals: {
        key: 'not_equals',
        regex: /^!\(consumer\.(\w+)\.(\w+)\.equals\("([^"]+)"\)\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!(consumer.${entity}.${entityAttribute}.equals("${attributeValue}"))`
                : `!(consumer.${entityAttribute}.equals("${attributeValue}"))`,
    },
    contains: {
        key: 'contains',
        regex: /^!\(consumer\.(\w+)\.contains\("([^"]+)"\)\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${entityAttribute}.contains("${attributeValue}")`
                : `consumer.${entityAttribute}.contains("${attributeValue}")`,
    },
    not_contains: {
        key: 'not_contains',
        regex: /^!\(consumer\.(\w+)\.contains\("([^"]+)"\)\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!(consumer.${entity}.${entityAttribute}.contains("${attributeValue}"))`
                : `!(consumer.${entityAttribute}.contains("${attributeValue}"))`,
    },
    between_numbers: {
        key: 'between_numbers',
        regex: /^consumer\.(\w+)\.(\w+)\s*>=\s*(\d+)\s*&&\s*consumer\.\1\.\2\s*<=\s*(\d+)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${entityAttribute} >= ${attributeValue.startValue} && consumer.${entity}.${entityAttribute} <= ${attributeValue.endValue}`
                : `consumer.${entityAttribute} >= ${attributeValue.startValue} && consumer.${entityAttribute} <= ${attributeValue.endValue}`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entity: extractedData[0],
                entityAttribute: extractedData[1],
                attributeValue: {
                    startValue: extractedData[2],
                    endValue: extractedData[3],
                },
            };
        },
    },
    not_between_numbers: {
        key: 'not_between_numbers',
        regex: /^!\(consumer\.(\w+)\s*>=\s*(\d+)\s*&&\s*consumer\.\1\s*<=\s*(\d+)\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!(consumer.${entity}.${entityAttribute} >= ${attributeValue.startValue} && consumer.${entity}.${entityAttribute} <= ${attributeValue.endValue})`
                : `!(consumer.${entityAttribute} >= ${attributeValue.startValue} && consumer.${entityAttribute} <= ${attributeValue.endValue})`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    startValue: extractedData[1],
                    endValue: extractedData[2],
                },
            };
        },
    },
    is_multiple: {
        key: 'is_multiple',
        regex: /"(.*?)".isMultiple\(consumer\.(\w+)\)/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.isMultiple("${attributeValue}",consumer.${entity}.${entityAttribute})`
                : `consumer.isMultiple("${attributeValue}",consumer.${entityAttribute})`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [attributeValue, entityAttribute] = match.slice(1);
            if (attributeValue.split(',').length <= 1) {
                return {
                    entityAttribute,
                    attributeValue,
                    operation: 'contains',
                };
            } else {
                return {
                    entityAttribute,
                    attributeValue,
                };
            }
        },
    },
    is_not_multiple: {
        key: 'is_not_multiple',
        regex: /!\"\((.*?)\"\.contains\(consumer\.(\w+)\)\)/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!("${attributeValue}".contains(consumer.${entity}.${entityAttribute}))`
                : `!("${attributeValue}".contains(consumer.${entityAttribute}))`,
        generateData: (regex, expression) => {
            let operation;
            const match = expression.match(regex);
            const [attributeValue, entityAttribute] = match.slice(1);
            if (attributeValue.split(',').length <= 1) {
                return {
                    entityAttribute,
                    attributeValue,
                    operation: 'contains',
                };
            } else {
                return {
                    entityAttribute,
                    attributeValue,
                    operation: 'contains',
                };
            }
        },
    },
    is_null: {
        key: 'is_null',
        regex: /consumer\.(\w+)\.(\w+)==null/,
        generate: (entity, attributeName) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}==null`
                : `consumer.${attributeName}==null`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [entityAttribute] = match.slice(1);
            return {
                entityAttribute,
                attributeValue: 'True',
            };
        },
    },
    is_not_null: {
        key: 'is_not_null',
        regex: /consumer\.(\w+)\.(\w+)!=null/,
        generate: (entity, attributeName) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}!=null`
                : `consumer.${attributeName}!=null`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [entityAttribute] = match.slice(1);
            return {
                entityAttribute,
                attributeValue: 'True',
            };
        },
    },
    starts_with: {
        key: 'starts_with',
        regex: /^consumer\.(\w+)\.(\w+)\.startsWith\("([^"]+)"\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${entityAttribute}.startsWith("${attributeValue}")`
                : `consumer.${entityAttribute}.startsWith("${attributeValue}")`,
    },
    not_starts_with: {
        key: 'not_starts_with',
        regex: /^!\(consumer\.(\w+)\.(\w+)\.startsWith\("([^"]+)"\)\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!(consumer.${entity}.${entityAttribute}.startsWith("${attributeValue}"))`
                : `!(consumer.${entityAttribute}.startsWith("${attributeValue}"))`,
    },
    ends_with: {
        key: 'ends_with',
        regex: /^consumer\.(\w+)\.(\w+)\.endsWith\("([^"]+)"\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${entityAttribute}.endsWith("${attributeValue}")`
                : `consumer.${entityAttribute}.endsWith("${attributeValue}")`,
    },
    not_ends_with: {
        key: 'not_ends_with',
        regex: /^!\(consumer\.(\w+)\.(\w+)\.endsWith\("([^"]+)"\)\)$/,
        generate: (entity, entityAttribute, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!(consumer.${entity}.${entityAttribute}.endsWith("${attributeValue}"))`
                : `!(consumer.${entityAttribute}.endsWith("${attributeValue}"))`,
    },
    between_dates: {
        key: 'between_dates',
        regex: /^\(consumer\.(\w+)\.(\w+)\.isAfter\(java\.time\.LocalDate\.parse\("([^"]+)"\)\) \|\| consumer\.\1\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)\) \&\& \(consumer\.\1\.isBefore\(java\.time\.LocalDate\.parse\("([^"]+)"\)\) \|\| consumer\.\1\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)\)/,
        generate: (entity, entityAttribute, attributeValue) => {
            const fromInputDate = format(
                attributeValue?.fromInputDate,
                'yyyy-MM-dd'
            );
            const toInputDate = format(
                attributeValue?.toInputDate,
                'yyyy-MM-dd'
            );
            if (entity.toLowerCase() !== 'consumer')
                return `(consumer.${entity}.${entityAttribute}.isAfter(java.time.LocalDate.parse("${fromInputDate}")) || consumer.${entity}.${entityAttribute}.isEqual(java.time.LocalDate.parse("${fromInputDate}"))) && (consumer.${entity}.${entityAttribute}.isBefore(java.time.LocalDate.parse("${toInputDate}")) || consumer.${entity}.${entityAttribute}.isEqual(java.time.LocalDate.parse("${toInputDate}")))`;
            else
                return `(consumer.${entityAttribute}.isAfter(java.time.LocalDate.parse("${fromInputDate}")) || consumer.${entityAttribute}.isEqual(java.time.LocalDate.parse("${fromInputDate}"))) && (consumer.${entityAttribute}.isBefore(java.time.LocalDate.parse("${toInputDate}")) || consumer.${entityAttribute}.isEqual(java.time.LocalDate.parse("${toInputDate}")))`;
        },
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    fromInputDate: toDate(extractedData[1]),
                    toInputDate: toDate(extractedData[3]),
                },
            };
        },
    },
    not_between_dates: {
        key: 'not_between_dates',
        regex: /^!\(\(consumer\.(\w+)\.(\w+)\.isAfter\(java\.time\.LocalDate\.parse\("([^"]+)"\)\) \|\| consumer\.\1\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)\)\s*&&\s*\(consumer\.\1\.isBefore\(java\.time\.LocalDate\.parse\("([^"]+)"\)\) \|\| consumer\.\1\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)\)\)$/,
        generate: (entity, attributeName, attributeValue) => {
            const fromInputDate = format(
                attributeValue?.fromInputDate,
                'yyyy-MM-dd'
            );
            const toInputDate = format(
                attributeValue?.toInputDate,
                'yyyy-MM-dd'
            );
            if (entity.toLowerCase() !== 'consumer')
                return `!((consumer.${entity}.${attributeName}.isAfter(java.time.LocalDate.parse("${fromInputDate}")) || consumer.${entity}.${attributeName}.isEqual(java.time.LocalDate.parse("${fromInputDate}"))) && (consumer.${entity}.${attributeName}.isBefore(java.time.LocalDate.parse("${toInputDate}")) || consumer.${entity}.${attributeName}.isEqual(java.time.LocalDate.parse("${toInputDate}"))))`;
            else
                return `!((consumer.${attributeName}.isAfter(java.time.LocalDate.parse("${fromInputDate}")) || consumer.${attributeName}.isEqual(java.time.LocalDate.parse("${fromInputDate}"))) && (consumer.${attributeName}.isBefore(java.time.LocalDate.parse("${toInputDate}")) || consumer.${attributeName}.isEqual(java.time.LocalDate.parse("${toInputDate}"))))`;
        },
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    fromInputDate: toDate(extractedData[1]),
                    toInputDate: toDate(extractedData[3]),
                },
            };
        },
    },
    on_or_after: {
        key: 'on_or_after',
        regex: /consumer\.(\w+)\.(\w+)\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\) \|\| consumer\.(\w+)\.isAfter\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)/,
        generate: (entity, attributeName, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}")) || consumer.${entity}.${attributeName}.isAfter(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`
                : `consumer.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}")) || consumer.${attributeName}.isAfter(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    givenDate: toDate(extractedData[1]),
                },
            };
        },
    },
    on_or_before: {
        key: 'on_or_before',
        regex: /consumer\.(\w+)\.(\w+)\.isBefore\(java\.time\.LocalDate\.parse\("([^"]+)"\)\) \|\| consumer\.(\w+)\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)/,
        generate: (entity, attributeName, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}.isBefore(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}")) || consumer.${entity}.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`
                : `consumer.${attributeName}.isBefore(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}")) || consumer.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    givenDate: toDate(extractedData[1]),
                },
            };
        },
    },
    after: {
        key: 'after',
        regex: /consumer\.(\w+)\.(\w+)\.isAfter\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)/,
        generate: (entity, attributeName, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}.isAfter(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`
                : `consumer.${attributeName}.isAfter(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    givenDate: toDate(extractedData[1]),
                },
            };
        },
    },
    before: {
        key: 'before',
        regex: /consumer\.(\w+)\.(\w+)\.isBefore\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)/,
        generate: (entity, attributeName, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}.isBefore(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`
                : `consumer.${attributeName}.isBefore(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(2);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    givenDate: toDate(extractedData[1]),
                    // givenDate: extractedData[1],
                },
            };
        },
    },
    on: {
        key: 'on',
        regex: /^consumer\.(\w+)\.(\w+)\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)$/,
        generate: (entity, attributeName, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `consumer.${entity}.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`
                : `consumer.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}"))`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    givenDate: toDate(extractedData[1]),
                },
            };
        },
    },
    not_on: {
        key: 'not_on',
        regex: /^!\(consumer\.(\w+)\.(\w+)\.isEqual\(java\.time\.LocalDate\.parse\("([^"]+)"\)\)\)$/,
        generate: (entity, attributeName, attributeValue) =>
            entity.toLowerCase() !== 'consumer'
                ? `!(consumer.${entity}.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}")))`
                : `!(consumer.${attributeName}.isEqual(java.time.LocalDate.parse("${format(
                      attributeValue?.givenDate,
                      'yyyy-MM-dd'
                  )}")))`,
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const extractedData = match.slice(1);
            return {
                entityAttribute: extractedData[0],
                attributeValue: {
                    givenDate: toDate(extractedData[1]),
                },
            };
        },
    },
    between_period: {
        key: 'between_period',
        regex: /^consumer\.isDateInRange\(consumer\.(\w+)\.(\w+),(-?\w+),(-?\w+),"(\w+)"\)$/,
        generate: (attributeName, attributeValue = {}) => {
            const { startValue, endValue, dateUnit } = attributeValue;
            return `consumer.isDateInRange(consumer.${attributeName},${startValue},${endValue},"${dateUnit}")`;
        },
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [attributeName, startValue, endValue, dateUnit] =
                match.slice(1);
            return {
                entityAttribute: attributeName,
                attributeValue: {
                    startValue,
                    endValue,
                    dateUnit,
                },
            };
        },
    },
    not_between_period: {
        key: 'not_between_period',
        regex: /^!\(consumer\.isDateInRange\(consumer\.(\w+)\.(\w+),(-?\w+),(-?\w+),"(\w+)"\)\)$/,
        generate: (attributeName, attributeValue) => {
            const { startValue, endValue, dateUnit } = attributeValue;
            return `!(consumer.isDateInRange(consumer.${attributeName},${startValue},${endValue},"${dateUnit}"))`;
        },
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [attributeName, startValue, endValue, dateUnit] =
                match.slice(1);
            return {
                entityAttribute: attributeName,
                attributeValue: {
                    startValue,
                    endValue,
                    dateUnit,
                },
            };
        },
    },
    within_this: {
        key: 'within_this',
        regex: /^consumer\.withinThisDateUnit\(consumer\.(\w+)\.(\w+),\s*"(\w+)"\)$/,
        generate: (attributeName, attributeValue) => {
            const { dateUnit } = attributeValue;
            return `consumer.withinThisDateUnit(consumer.${attributeName}, "${dateUnit}")`;
        },
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [attributeName, dateUnit] = match.slice(1);
            return {
                entityAttribute: attributeName,
                attributeValue: {
                    dateUnit,
                },
            };
        },
    },
    not_within_this: {
        key: 'not_within_this',
        regex: /^!\(consumer\.withinThisDateUnit\(consumer\.(\w+)\.(\w+),\s*"(\w+)"\)\)$/,
        generate: (attributeName, attributeValue) => {
            const { dateUnit } = attributeValue;
            return `!(consumer.withinThisDateUnit(consumer.${attributeName}, "${dateUnit}"))`;
        },
        generateData: (regex, expression) => {
            const match = expression.match(regex);
            const [attributeName, dateUnit] = match.slice(1);
            return {
                entityAttribute: attributeName,
                attributeValue: {
                    dateUnit,
                },
            };
        },
    },
};

const MVEL_EXPRESSION_BY_CONDITION = {
    is_multiple: 'contains',
    is_not_multiple: 'contains',
    after: 'isAfter',
    before: 'isBefore',
    on: 'isEqual',
    is_null: '==',
    is_not_null: '!=',
    not_equals: '!=',
    equals: '==',
    not_starts_with: 'startsWith',
    not_ends_with: 'endswith',
    not_on: 'isEqual',
    on_or_after: 'isAfter',
    on_or_before: 'isBefore',
    within_this: 'withinThisDateUnit',
    not_within_this: 'isDateInRange',
    between_period: 'isDateInRange',
    not_between_period: 'isDateInRange',
};

const anyAttributesHaveValue = (obj) => {
    return Object.values(obj).some(
        (value) => value !== null && value !== undefined && value !== ''
    );
};

const customFunctionMVEL = (
    { aggregator, rules, entity },
    attributeTypeBasedOnOperation
) => {
    let filterCriteria = '';
    // Generate filter criteria string by looping through rules
    rules.forEach((rule) => {
        const { entityAttribute, operation, attributeValue } = rule;
        const inputType = getInputTypeByOperation(
            operation,
            attributeTypeBasedOnOperation
        );
        let value = Array.isArray(attributeValue)
            ? attributeValue.join(',')
            : attributeValue;
        switch (inputType) {
            case 'text':
                switch (operation) {
                    case 'is_null':
                    case 'is_not_null':
                        filterCriteria =
                            filterCriteria +
                            `'{"${entityAttribute}": {"conditionType": "${operation}"}}',`;
                        break;
                    default:
                        filterCriteria =
                            filterCriteria +
                            `'{"${entityAttribute}": {"conditionType": "${operation}", "attributeValue": "${value}"}}',`;
                }
                break;
            case 'date':
                const { givenDate = '' } = attributeValue;
                const formatGivenDate = format(givenDate, 'yyyy-MM-dd');
                filterCriteria =
                    filterCriteria +
                    `'{"${entityAttribute}": {"conditionType": "${operation}", "givenDate": "${formatGivenDate}"}}',`;
                break;
            case '2-dates':
                const fromInputDate = format(
                    attributeValue.fromInputDate,
                    'yyyy-MM-dd'
                );
                const toInputDate = format(
                    attributeValue.toInputDate,
                    'yyyy-MM-dd'
                );
                filterCriteria =
                    filterCriteria +
                    `'{"${entityAttribute}": {"conditionType": "${operation}", "fromInputDate": "${fromInputDate}", "toInputDate": "${toInputDate}"}}',`;
                break;
            case '2-input-1-dropdown':
                const { startValue, endValue, dateUnit } = attributeValue;
                filterCriteria =
                    filterCriteria +
                    `'{"${entityAttribute}": {"conditionType": "${operation}", "startValue": "${startValue}", "endValue": "${endValue}", "dateUnit": "${dateUnit}"}}',`;
                break;
            case '2-number':
                filterCriteria =
                    filterCriteria +
                    `'{"${entityAttribute}": {"conditionType": "${operation}", "startValue": "${attributeValue.startValue}", "endValue": "${attributeValue.endValue}"}',`;
                break;
            case 'range_select':
                filterCriteria =
                    filterCriteria +
                    `'{"${entityAttribute}": {"conditionType": "${operation}", "dateUnit": "${attributeValue.dateUnit}"}',`;
                break;
            default:
                filterCriteria =
                    filterCriteria +
                    `'{"${entityAttribute}": {"conditionType": "${operation}", "attributeValue": "${value}"}}',`;
        }
    });

    // Check if to use applyFilterWithSorting or applyFilterWithCount
    if (aggregator.orderBy) {
        // applyFilterWithSorting syntax
        return `consumer.applyFilterWithSorting(consumer.${entity}, "${
            aggregator.orderBy
        }", "${aggregator.countOperation || ''}", "${
            aggregator.orderAttributeName
        }", ${filterCriteria})`;
    } else if (aggregator.countOperation) {
        // applyFilterWithCount syntax
        return `consumer.applyFilterWithCount(consumer.${entity}, "${
            aggregator.countOperation
        }", "${aggregator.countValue || ''}", ${filterCriteria})`;
    }
};

const nonCustomFunctionMVEL = (entity, rules) => {
    let mvelExpression = '';
    rules.forEach((rule, idx) => {
        let ruleExpression = '';
        const { entityAttribute, operation, attributeValue } = rule;
        const newOperation =
            MVEL_EXPRESSION_BY_CONDITION[operation] || operation;
        if (!attributeValue) {
            return;
        }

        if (MVEL_EXPRESSION_MAPPER[operation]) {
            const { generate } = MVEL_EXPRESSION_MAPPER[operation];
            ruleExpression = generate(entity, entityAttribute, attributeValue);
        } else {
            ruleExpression =
                entity.toLowerCase() != 'consumer'
                    ? `consumer.${entity}.${entityAttribute}${newOperation}"${attributeValue}"`
                    : `consumer.${entityAttribute}${newOperation}"${attributeValue}"`;
        }
        if (idx >= 1) {
            mvelExpression = mvelExpression + ' && ' + `(${ruleExpression})`;
        } else if (rules.length > 1) {
            mvelExpression = `(${ruleExpression})`;
        } else {
            mvelExpression = `(${ruleExpression})`;
        }
    });
    return mvelExpression;
};

const generateCondition = (data, attributeTypeBasedOnOperation, isNested) => {
    let ruleCondition = '';
    data.map((item, itemIndex) => {
        let individualMvelExpression = '';
        const { entity, aggregator, rules, connector, groups } = item;

        if (anyAttributesHaveValue(aggregator)) {
            individualMvelExpression = customFunctionMVEL(
                { rules, entity, aggregator },
                attributeTypeBasedOnOperation
            );
            individualMvelExpression = individualMvelExpression.replace(
                /,\s*\)$/,
                ')'
            );
        } else {
            individualMvelExpression = nonCustomFunctionMVEL(
                entity,
                rules,
                attributeTypeBasedOnOperation
            );
        }
        if (groups.length) {
            let nestedCondition = generateCondition(
                groups,
                attributeTypeBasedOnOperation,
                true
            );
            nestedCondition = nestedCondition.replace(/\s*(&&|\|\|)\s*$/, '');
            // ruleCondition += `((${individualMvelExpression})  ${groups[0].connector} ${nestedCondition})`;
            ruleCondition += `(${nestedCondition})`;
        } else {
            ruleCondition += `(${individualMvelExpression})`;
        }
        if (isNested) {
            ruleCondition = ruleCondition + ` ${connector} `;
        } else if (data.length > 1 && connector) {
            ruleCondition = ruleCondition + ` ${connector} `;
        }
        // No valid aggregator conditions found
    });
    return ruleCondition;
};

export const generateMvelExpression = (
    data,
    actionsData,
    attributeTypeBasedOnOperation
) => {
    let ruleCondition = generateCondition(data, attributeTypeBasedOnOperation);
    ruleCondition = ruleCondition.replace(/\s*&&\s*$/, '');
    // parseMvelExpression2(mvelExpression);
    let ruleAction = generateActionsExpression(actionsData);
    return [ruleCondition, ruleAction];
};

export const generateActionsExpression = (actionsData) => {
    //const result = `output.setAction('${JSON.stringify(actionsData).slice(1,-1)}')`
    const result = `output.setPoints(${actionsData.attributeValue});output.setoNamespace('LOYALTY_TRANSACTION')`;
    return result;
};
export const parseActionExpression = (actionExpression) => {
    // Step 1: Extract the JSON-like content inside input.setAction(...)
    //const regex = /setAction\('(.+)'\)/;
    const regex = /setPoints\((\d+)\)/;
    const match = actionExpression.match(regex);
    if (!match) return {}; // Return an empty object if no match is found

    // Step 2: extract the point value
    const action = {
        domain: 'Consumer',
        attributeName: 'points',
        operation: '==',
        attributeValue: match[1]
    }
    return action;

    // // Step 3: Replace single quotes around keys and values with double quotes for valid JSON
    // jsonString = jsonString.replace(/'/g, '"');

    // // Step 4: Parse the JSON string into an array of objects
    // try {
    //     const actions = JSON.parse(jsonString);
    //     return actions;
    // } catch (error) {
    //     console.error('Invalid JSON format:', error);
    //     return []; // Return an empty array if JSON parsing fails
    // }
};

export const isStringEmpty = (str) => {
    return typeof str === 'string' && str.trim().length === 0;
};

export const isObjectAndNotEmpty = (value) => {
    return (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        Object.keys(value).length !== 0
    );
};

export const containsPattern = (inputString, withBracket = false) => {
    const pattern1 = withBracket
        ? '(consumer.applyFilterWithSorting('
        : 'consumer.applyFilterWithSorting(';
    const pattern2 = withBracket
        ? '(consumer.applyFilterWithCount('
        : 'consumer.applyFilterWithCount(';
    return inputString.includes(pattern1) || inputString.includes(pattern2);
};

const getOperatorsUsingContentArray = (contentArray, expression) => {
    const operators = [];
    let currentIndex = 0;

    // Iterate over the groups to find the operators between them
    for (let i = 0; i < contentArray.length - 1; i++) {
        // Find the starting index of the current group and the next group
        const currentGroupEnd =
            expression.indexOf(contentArray[i], currentIndex) +
            contentArray[i].length;
        const nextGroupStart = expression.indexOf(
            contentArray[i + 1],
            currentGroupEnd
        );

        // Extract the substring between the end of the current group and the start of the next group
        const betweenGroups = expression
            .slice(currentGroupEnd, nextGroupStart)
            .trim();

        // Identify the operator and push it to the result array
        if (betweenGroups.includes('&&')) {
            operators.push('&&');
        } else if (betweenGroups.includes('||')) {
            operators.push('||');
        }

        // Update the current index to continue searching
        currentIndex = nextGroupStart;
    }

    return operators;
};

const generateContentArray = (expression) => {
    let stack = []; // Stack to track bracket positions
    let contentArray = []; // Array to store top-level expressions
    let start = 0; // Start index for each top-level expression

    for (let i = 0; i < expression.length; i++) {
        if (expression[i] === '(') {
            stack.push(i);
            if (!start) {
                start = i + 1;
            }
        } else if (expression[i] === ')') {
            if (stack.length > 0) {
                stack.pop();
                // If the stack is empty, we are at a top-level closing bracket
                if (stack.length === 0) {
                    let content = expression.slice(start, i).trim();
                    contentArray.push(content);
                    start = 0; // Update start for the next potential top-level expression
                }
            }
        }
    }

    return contentArray;
};

const parseNestedConditions = (expression) => {};

// const parseFilterCriteria = (filterCriteria) => {
//   let rules = [];
//   // Parse filterCriteria into additional rules if necessary
//   const criteriaPattern = /{"(\w+)": {"conditionType": "([^"]+)", "attributeValue": "([^"]+)"}}/g;
//   let criteriaMatch;
//   while ((criteriaMatch = criteriaPattern.exec(filterCriteria)) !== null) {
//       const [, entityAttribute, conditionType, attributeValue] = criteriaMatch;
//       rules.push({
//           entityAttribute,
//           operation: conditionType,
//           attributeValue
//       });
//   };
//   return rules;
// }

const parseFilterCriteria = (filterCriteria) => {
    let rules = [];
    // Enhanced pattern to match various formats in filterCriteria
    // const criteriaPattern = /{"(\w+)":\s*{\s*"conditionType":\s*"([^"]+)",\s*(?:(?:"attributeValue":\s*"([^"]+)")|(?:"fromCount":\s*"([^"]+)",\s*"toCount":\s*"([^"]+)")|(?:"givenDate":\s*"([^"]+)")|(?:"fromInputDate":\s*"([^"]+)",\s*"toInputDate":\s*"([^"]+)")|(?:"startValue":\s*"([^"]+)",\s*"endValue":\s*"([^"]+)")|(?:"startValue":\s*"([^"]+)",\s*"endValue":\s*"([^"]+)",\s*"dateUnit":\s*"([^"]+)")|(?:"dateUnit":\s*"([^"]+)"))\s*}/g;
    const criteriaPattern =
        /{"(\w+)":\s*{\s*"conditionType":\s*"([^"]+)"(?:,\s*"attributeValue":\s*"([^"]+)")?(?:,\s*"fromCount":\s*"([^"]+)",\s*"toCount":\s*"([^"]+)")?(?:,\s*"givenDate":\s*"([^"]+)")?(?:,\s*"fromInputDate":\s*"([^"]+)",\s*"toInputDate":\s*"([^"]+)")?(?:,\s*"startValue":\s*"([^"]+)",\s*"endValue":\s*"([^"]+)")?(?:,\s*"startValue":\s*"([^"]+)",\s*"endValue":\s*"([^"]+)",\s*"dateUnit":\s*"([^"]+)")?(?:,\s*"dateUnit":\s*"([^"]+)")?\s*}/g;

    let criteriaMatch;
    while ((criteriaMatch = criteriaPattern.exec(filterCriteria)) !== null) {
        const [
            ,
            entityAttribute,
            conditionType,
            attributeValueMatch,
            fromCount,
            toCount,
            givenDate,
            fromInputDate,
            toInputDate,
            startValue,
            endValue,
            ,
            ,
            ,
            dateUnit,
        ] = criteriaMatch;

        // Create a rule object based on matched values
        const rule = {
            id: uuidv4(),
            entityAttribute,
            operation: conditionType,
        };

        if (conditionType === 'is_null' || conditionType === 'is_not_null') {
            rule.attributeValue = 'True';
        }

        // Add specific properties based on condition type
        if (attributeValueMatch !== undefined) {
            rule.attributeValue = attributeValueMatch;
        }
        if (fromCount !== undefined && toCount !== undefined) {
            rule.attributeValue = {};
            rule.attributeValue.fromCount = fromCount;
            rule.attributeValue.toCount = toCount;
        }
        if (givenDate !== undefined) {
            rule.attributeValue = {};
            rule.attributeValue.givenDate = toDate(givenDate);
        }
        if (fromInputDate !== undefined && toInputDate !== undefined) {
            rule.attributeValue = {};
            rule.attributeValue.fromInputDate = toDate(fromInputDate);
            rule.attributeValue.toInputDate = toDate(toInputDate);
        }
        if (
            startValue !== undefined &&
            endValue !== undefined &&
            dateUnit === undefined
        ) {
            rule.attributeValue = {};
            rule.attributeValue.startValue = startValue;
            rule.attributeValue.endValue = endValue;
        }
        if (
            startValue !== undefined &&
            endValue !== undefined &&
            dateUnit !== undefined
        ) {
            rule.attributeValue = {};
            rule.attributeValue.startValue = startValue;
            rule.attributeValue.endValue = endValue;
            rule.attributeValue.dateUnit = dateUnit;
        }
        if (
            startValue === undefined &&
            endValue === undefined &&
            dateUnit !== undefined
        ) {
            rule.attributeValue = {};
            rule.attributeValue.dateUnit = dateUnit;
        }

        rules.push(rule);
    }

    return rules;
};

const parseSortingPattern = (expression) => {
    // const aggPattern = /applyFilterWithSorting\(([^,]+), "([^"]*)", "([^"]*)", "([^"]*)", ('{.*?}'|"{.*?}")\)/;
    const aggPattern =
        /applyFilterWithSorting\(([^,]+), "([^"]*)", "([^"]*)", "([^"]*)"(, ('{.*?}'|"{.*?}"))?\)/;

    const aggMatch = expression.match(aggPattern);
    if (aggMatch) {
        const [
            ,
            list,
            orderBy,
            countOperation,
            orderAttributeName,
            filterCriteria,
        ] = aggMatch;
        const aggregator = { orderBy, countOperation, orderAttributeName };
        const rules = parseFilterCriteria(filterCriteria);
        return {
            entity: list.replace(/^consumer\./, '').trim(),
            aggregator,
            rules,
        };
    }
};

const parseCountPattern = (expression) => {
    // const aggPattern = /applyFilterWithCount\(([^,]+), "([^"]*)", "([^"]*)", ('{.*?}'|"{.*?}")\)/;
    const aggPattern =
        /applyFilterWithCount\(([^,]+), "([^"]*)", "([^"]*)"(, ('{.*?}'|"{.*?}"))?\)/;
    const aggMatch = expression.match(aggPattern);
    if (aggMatch) {
        const [, list, countOperation, countValue, filterCriteria] = aggMatch;
        const aggregator = { countValue, countOperation };
        const rules = parseFilterCriteria(filterCriteria);
        return {
            entity: list.replace(/^consumer\./, '').trim(),
            aggregator,
            rules,
        };
    }
};

const parseCustomFunctions = (expression) => {
    if (expression.includes('applyFilterWithSorting')) {
        return parseSortingPattern(expression);
    } else if (expression.includes('applyFilterWithCount')) {
        return parseCountPattern(expression);
    }
};

const parseNonCustomFunctions = (mvelExpression) => {
    const rules = [];
    const conditions = generateContentArray(mvelExpression);
    //
    conditions.forEach((condition) => {
        // console.log("condition", condition)
        let matchFound = false;
        for (let key in MVEL_EXPRESSION_MAPPER) {
            if (MVEL_EXPRESSION_MAPPER.hasOwnProperty(key)) {
                const mapper = MVEL_EXPRESSION_MAPPER[key];
                const match = condition.match(mapper.regex);

                // If a match is found, extract the data
                if (match) {
                    matchFound = true;
                    if (!mapper.generateData) {
                        mapper.generateData = (regex, expression) => {
                            const match = expression.match(regex);
                            const [entity, entityAttribute, attributeValue] =
                                match.slice(1);
                            return {
                                entity,
                                entityAttribute,
                                attributeValue,
                            };
                        };
                    }
                    rules.push({
                        id: uuidv4(),
                        operation: mapper.key,
                        ...mapper?.generateData(mapper.regex, condition),
                    });
                    return;
                }
            }
        }
        if (!matchFound) {
            const regex = /^consumer\.(\w+)\.(\w+)\s*([=!><]+)\s*"?([^"]+)"?$/;
            const match = condition.match(regex);
            if (match) {
                const [entity, entityAttribute, operation, attributeValue] =
                    match.slice(1);
                rules.push({
                    entity,
                    entityAttribute,
                    operation,
                    attributeValue,
                    id: uuidv4(),
                });
                matchFound = false;
            }
        } else {
            matchFound = false;
        }
    });

    return { rules };
};

const parseNestedMvelExpression = (expression) => {
    const nestedGroups = generateContentArray(expression);
    const connectors = getOperatorsUsingContentArray(nestedGroups, expression);
    const [data, groups] = parseMvelExpression(expression);
    groups.connector = connectors.pop();
    return { data, groups: [{ ...groups }] };
};

const containsThreeBracketsPattern = (group) => /^\(\(\(/.test(group);

export const parseMvelExpression = (expression, isNested) => {
    const parsedData = [];

    const groups = generateContentArray(expression);
    const connectors = getOperatorsUsingContentArray(groups, expression);

    groups.forEach((group, idx) => {
        parsedData[idx] = { ...INTIAL_GROUP, id: uuidv4() };
        if (
            containsPattern(group, true) ||
            containsThreeBracketsPattern(group)
        ) {
            // const data = parseMvelExpression(group);
            parsedData[idx] = {
                ...parsedData[idx],
                groups: parseMvelExpression(group, true),
            };
        } else if (containsPattern(group)) {
            const data = parseCustomFunctions(group);
            parsedData[idx] = { ...parsedData[idx], ...data };
        } else {
            const data = parseNonCustomFunctions(group);
            // console.log(data.rules[idx].entity)
            parsedData[idx] = {
                ...parsedData[idx],
                ...data,
                entity: data.rules[idx].entity,
            };
        }
        if (connectors.length) {
            if (isNested) {
                // if (idx > 0) {
                //   parsedData[idx].connector = connectors.pop();
                // }
                parsedData[idx].connector = connectors.shift();
            } else {
                parsedData[idx].connector = connectors.shift();
            }
        }
    });
    // console.log(parsedData)
    return parsedData;
};

export function getEditorConfig(editorConfig, market, category,brand) {
    return editorConfig?.[market]?.[category]?.[brand]?.data || null;
  };