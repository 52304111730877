export function extractEvent(condition) {
    const typeRegex = /input\.domain\s*==\s*'([^']+)'/;
    const typeMatch = condition.match(typeRegex);
    if (typeMatch && typeMatch[1]) {
        return typeMatch[1];
    }
    return '';
}
export function extractEventAttribute(condition) {
    const conditions = condition.match(/\(([^)]+)\)/g) || [];

    const firstCondition = conditions[0];
    const match = firstCondition.match(/consumer\.[^.]+\s*==/g);

    if (match && match.length >= 2) {
        const attributeName = match[1].match(/input\.([^ ]+)\s*==/)[1];
        return attributeName;
    }
    return '';
}

export function extractPoints(action) {
    const pointRegex = /output\.setPoints\((\d+)\)/;
    const pointsMatch = action.match(pointRegex);
    if (pointsMatch && pointsMatch[1]) {
        return pointsMatch[1];
    }
    return '';
}

export function parseRule(ruleString) {
    const conditions = [];
    let event = null;

    const regex = /\(([^)]+)\)/g;
    let match;
    while ((match = regex.exec(ruleString)) !== null) {
        const insideParentheses = match[1];
        const parts = insideParentheses.split(/\s*&&\s*/);

        let domain = null;
        let conditionPart = null;

        if (parts.length === 1) {
            conditionPart = parts[0];
        } else {
            domain = parts[0].split('==')[1].trim().replace(/['"]/g, '');
            conditionPart = parts[1];
        }

        const eventAttribute = conditionPart.split(/(==|>=|<=|>|<)/);
        const attributeName = eventAttribute[0].trim().replace('input.', '');
        const operation = eventAttribute[1].trim();
        const attributeValue = eventAttribute[2].trim().replace(/['"]/g, '');

        if (attributeName === 'points') {
            domain = 'CONSUMER';
        }
        if (event === null) {
            event = { domain, attributeName, operation, attributeValue };
        } else {
            conditions.push({
                domain: domain ?? 'CONSUMER',
                attributeName,
                operation,
                attributeValue,
                join: '&&',
            });
        }
    }

    return { event, conditions };
}
